.rev-feature {
    i {
        margin-right: 10px; } }

.rv-slider-three-upsub {
    color: #FFF; }

.banner {
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-size: cover;

    #stars, #stars2, #stars3 {
      position: absolute;
      z-index: 999; }

    .swiper-banner {
        overflow: hidden;

        .swiper-slide {
            height: 100vh;


            .slider-caption, .slider-img, .slide-two-image {
                opacity: 0;
                transition-delay: .7s; }

            &.swiper-slide-active {
                .slider-caption, .slider-img, .slide-two-image {

                    opacity: 1; } } }

        .slider-img {
            img {
                &:first-child {
                    margin-right: -30px; }

                &:last-child {
                    margin-left: -30px; } } } } }


.slider-caption {
    position: absolute;
    left: 16%;
    top: 50%;
    transform: translateY(-35%);

    h3 {
        margin-top: 0;
        font-size: 20px;
        font-family: "Montserrat";
        color: rgb(255, 255, 255);
        font-weight: 700;
        text-transform: uppercase;
        text-shadow: 0px 3px 0px rgba(0, 0, 0, 0.35); }

    h2 {

        font-size: 60px;
        font-family: "Montserrat";
        color: rgb(255, 255, 255);
        font-weight: bold;
        text-transform: uppercase;
        text-shadow: 0px 4px 0px rgba(0, 0, 0, 0.35); }

    p {
        font-size: 14px;
        color: #FFF;
        font-weight: 300; }

    &.caption-two {
        h3 {
            font-size: 20px;
            font-weight: 700;
            letter-spacing: 5px;
            text-shadow: 0px 3px 0px rgba(0, 0, 0, 0.2); }


        h2 {
            font-size: 50px;
            text-shadow: 0px 4px 0px rgba(0, 0, 0, 0.2);
            margin-top: 0; } } }



.slider-img {
    position: absolute;
    top: -10px;
    right: 18%;

    img {
        width: 130px; } }

.scene {
    width: 100%; }

.slide-two-image {
    position: absolute;
    top: 50%;
    right: 20%;
    transform: translateY(-50%); }


/* Nav Style */
.swiper-button-next, .swiper-button-prev {
    background-image: none;
    transition: all 0.3s ease-in-out;
    top: 50%;
    transform: translateY(120%);
    width: 150px;

    &:before {
        position: absolute;
        content: '';
        top: 12px; }

    &:after {
        width: 50px;
        height: 1px;
        background: #FFF;
        position: absolute;
        content: '';
        top: 22px;
        transition: all 0.3s ease-in-out; }

    span {
        display: inline-block;
        width: 64px;
        height: 64px;
        position: absolute;
        top: -46px;
        overflow: hidden;
        transform: translateY(-20px);
        opacity: 0;
        transform: scale(0);
        transition: all 0.15s ease-in-out;


        > img {

            width: auto;
            height: 100%;
            -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            -webkit-animation-name: fadeInUp;
            animation-name: fadeInUp; } }

    &:hover {
        span {
            opacity: 1;
            transform: scale(1);

            &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                transform: scale(1); } }

        &:after {
            width: 165px; } } }

.swiper-button-prev {
    &:after {
        left: 15px; }

    &:before {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid #FFF;
        left: 10px; } }


.swiper-button-next {
    &:after {
        right: 15px; }

    &:before {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid #FFF;
        right: 7px; } }


.swiper-button-prev span:nth-child(1) {
    width: 32px;
    height: 32px;
    left: 26px;
    top: -14px;
    transform: scale(0);
    transition: all 0.3s ease-in-out; }


.swiper-button-prev span:nth-child(2) {
    width: 48px;
    height: 48px;
    left: 63px;
    top: -30px;
    transform: scale(0);
    transition: all 0.45s ease-in-out; }


.swiper-button-prev span:nth-child(3) {
    left: 116px; }


.swiper-button-next span:nth-child(1) {
    left: -30px; }


.swiper-button-next span:nth-child(2) {
    width: 48px;
    height: 48px;
    right: 61px;
    top: -30px;
    transform: scale(0);
    transition: all 0.3s ease-in-out; }


.swiper-button-next span:nth-child(3) {
    width: 32px;
    height: 32px;
    right: 23px;
    top: -14px;
    transform: scale(0);
    transition: all 0.45s ease-in-out; }


/* Static Banner */
.static-banner-content {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%,-50%);

  h4 {
    font-size: 30px;
    color: #FFF;
    margin-top: 0;
    text-transform: uppercase; }

  h2 {
    font-size: 70px;
    line-height: 70px;
    color: #FFF;
    font-weight: 800;
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    margin-top: 0;

    span {
      color: $color_theme; } }

  p {
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    line-height: 26px;
    margin-bottom: 40px; }

  .dt-btn-two {
    margin: 0 auto; }

  .btn-active {
    margin-left: 20px;
    background: $color_theme;
    border-color: transparent;

    &:hover {
      color: #000;
      border-color: transparent;

      &:before {
        background: #FFF; } } } }



/* Responsive */
@media screen and (max-width: 1440px) {
    .slider-caption {
        h3 {
            font-size: 18px; }

        h2 {
            font-size: 45px; }

        &.caption-two {
            h2 {
                font-size: 40px; } } }

    .slide-two-image {
        right: 8%; }

    .slider-img {
        right: 5%; }

    .static-banner-content {
      h2 {
        font-size: 60px;
        line-height: 60px; } } }

@media screen and (max-width: 1280px) {

    .slider-caption {
        h2 {
            font-size: 40px; }

        p {
            max-width: 500px;
            br {
                display: none; } } }


    .slider-img {
        img {
            width: 120px; } } }


@media screen and (max-width: 1024px) {
    .slider-caption {
        left: 6%;
        &.caption-two {
            h2 {
                font-size: 30px;
                text-shadow: 0px 3px 0px rgba(0, 0, 0, 0.2); } } }

    .slide-two-image {
        height: 280px;
        width: 280px;

        img {
            width: 100%; } }

    .static-banner-content {
      h2 {
        font-size: 50px;
        line-height: 50px; } } }

@media screen and (max-width: 768px) {
    .slider-caption {
        h3 {
            font-size: 15px;
            margin-bottom: 5px; }

        h2 {
            font-size: 24px;
            margin-top: 0; }

        p {
            max-width: 350px;
            font-size: 12px; }

        .dt-btn-two {
            max-width: 160px;
            line-height: 44px;
            margin-top: 10px;

            i {
                right: 14px;
                top: 16px; }

            svg {
                height: 45px; }

            rect {
                stroke-dasharray: 68, 78, 113; }

            &:hover, &:focus {
                rect {
                    stroke-dasharray: 98, 1, 0; }

                i {
                    right: 8px; } } }

        &.caption-two {
            h3 {
                font-size: 13px;
                text-shadow: 0px 3px 0px rgba(0, 0, 0, 0.2); }

            h2 {
                font-size: 24px;
                text-shadow: 0px 3px 0px rgba(0, 0, 0, 0.2); } } }

    .slider-img {
        right: 2%;
        img {
            width: 76px;
            height: 280px;

            &:first-child {
                margin-right: -12px; } } }

    .slide-two-image {
        height: 200px;
        width: 200px;
        right: 5%;
        transform: translateY(-28%);

        img {
            width: 100%; } }

    .static-banner-content {
      h4 {
        font-size: 20px; }

      h2 {
        font-size: 35px;
        line-height: 35px; }

      p {
        display: none; } } }


@media screen and (max-width: 500px) {

    .slider-caption {
        &.caption-two {
            h2 {
                br {
                    display: none; } } } }

    .slider-img, .slide-two-image {
        display: none; }

    .static-banner-content {
        h4 {
          font-size: 16px; }

        h2 {
          font-size: 28px;
          line-height: 28px; } } }



