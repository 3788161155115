#login {
  padding: 100px 0; }

.login-wrapper {
  width: 80%;
  margin: 0 auto; }


.login-header {
  padding: 14px 20px;
  background: $color_theme;

  h3 {
    display: inline-block;
    margin: 0;
    font-size: 14px;
    color: #FFF; }

  .sing-up {
    float: right;
    color: #FFF;
    font-size: 22px; } }

.login-form {
  padding: 50px 100px;
  background: #f5f5f5;
  margin: 0 20px;

  .input-form {
    width: 100%;

    input {
      width: 100%;
      height: 60px;
      margin-bottom: 20px;
      padding: 12px 20px;
      font-size: 16px;
      border: 1px solid #c9d1ff;
      outline: none;
      transition: all 0.3s ease-in-out;

      &:hover {
        border-color: $color_theme; } } } }

.login-button {
  display: flex;
  align-items: center;

  p {
    input {
      margin-left: 10px; } }

  .width-1-3 {
    width: 33%;

    a {
      color: $color_theme; }


    .dt-btn {
      float: right;
      padding: 12px 50px;
      color: #FFF; } } }

#sign-up {
  padding: 100px 0; }
