#about-us {
  padding: 100px 0; }

.about-tab {
  .tabs {
    background-color: transparent;

    li {
      margin-right: 110px;
      border-right: none;
      position: relative;
      z-index: 100;

      &:after {
        position: absolute;
        content: '';
        height: 1px;
        width: 120px;
        background: #dfe4e7;
        right: -120%;
        top: 50%;
        z-index: -1; }

      &:last-child {
        margin-right: 0;

        &:after {
          display: none; } }

      &.current, &:hover {

        a {
          background: $color_theme;
          color: #FFF;
          &:after {
            display: none; } } }

      a {
        color: #000;
        border: 1px solid #dfe4e7;
        padding: 14px 30px;
        line-height: 14px;
        color: #5f5f5f;
        background: #eeeeee;
        font-weight: 400; } } } }

.about-tab {
  .tab_content {
    .content {
      h4 {
        font-weight: 700;
        font-size: 22px; }

      .dt-btn {
        margin-top: 20px; } }

    .ab-thumb {
      max-width: 570px;
      margin: 0 auto;

      img {
        box-shadow: 0 10px 30px rgba(0,0,0,0.15);
        margin-top: 20px; } } } }

@media screen and (max-width: 1280px) {

  .about-tab {
    .tabs {
      background-color: transparent;

      li {
        margin-right: 70px; } } } }


@media screen and (max-width: 1024px) {

  .about-tab {
    .tabs {
      background-color: transparent;

      li {
        margin-right: 35px;

        &:after {
          display: none; } } } } }


@media screen and (max-width: 768px) {

   .about-tab {
    .tabs {
      background-color: transparent;

      li {
        margin-right: 35px;
        width: 30.7%;
        margin-right: 10px; } } } }

@media screen and (max-width: 500px) {

  #about-us {
    padding: 50px 0; }

  .about-tab {
    .tabs {
      background-color: transparent;

      li {
        width: 27.7%;
        margin-right: 10px; } }

    .tabs_item {
      margin-bottom: 0; } } }
