/*=========  Widgets Title  ==========*/
.widget-title {
    padding: 13px 30px;
    background: #eeeeee;
    font-size: 18px;
    color: #0e0e0e;
    border-bottom: 1px solid #d6d6d6;
    margin-bottom: 20px; }

.widget_dt_search {
    .search-form {
        width: 100%;

        label {
            position: relative;
            width: 100%;
            margin-bottom: 0; }

        .search-icon {
            position: absolute;
            right: 10px;
            top: 8px;
            cursor: pointer;

            i {
                color: #999999; } } }

    input[type="search"] {
        width: 100%;
        border: 1px solid #eeeeee;
        outline: none;
        padding: 8px 20px 8px 8px;
        color: #111;
        font-size: 14px;
        font-weight: 300; }

    .search-submit {
        display: none; } }

/*=========  Categories  ==========*/

.dt_custom_menu {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        line-height: 32px;

        a {
            color: #0e0e0e;
            font-size: 14px;

            i {
                margin-right: 10px; }

            &:hover {
                color: $color_theme;
                text-decoration: underline; } } } }

/*=========  Recent Post  ==========*/
.post-nav {

    padding: 20px 0 10px;
    list-style: none;

    li {
        display: inline-block;
        margin-right: -4px;
        a {
            display: block;
            background: #0e0e0e;
            padding: 10px 27px;
            border-right: 1px solid #2e2e2e;
            text-transform: uppercase;
            color: #FFF;

            &:hover,&:focus {
                background: $color_theme;
                text-decoration: none; } }


        &.active {
            a {
                background: $color_theme; } } } }

.resent-post {
    margin-bottom: 20px;
    border-bottom: 1px solid #dfe4e7;
    padding-bottom: 10px;

    &:last-child {
        border-bottom: none;
        margin-bottom: 0; }

    .post-thumbs {
        float: left; }

    .post-content {
        margin-left: 120px;

        h5 {
            margin: 0;

            a {
                color: #222832;
                line-height: 24px;
                font-size: 14px;

                &:hover {
                    color: $color_theme;
                    text-decoration: underline; } } }

        > a {
            display: inline-block;
            color: #6e6f70;
            font-size: 13px;
            font-weight: 300;
            margin-right: 10px;

            i {
                margin-right: 5px;
                color: $color_theme; }

            &:hover {
                color: $color_theme; } } } }


/*=========  Tags  ==========*/
.dt-tag {
    a {
        padding: 8px 24px;
        border: 1px solid #dfe4e7;
        background: #f5f5f7;
        display: inline-block;
        margin: 0 5px 7px 0;
        font-size: 14px;
        color: #0e0e0e;

        &:hover {
            background: $color_theme;
            color: #FFF; } } }


/*=========  Widget Style Two  ==========*/

.widget {
    position: relative; }


.widget-bg {
    background: #f7f7f7;
    padding: 30px 19px 20px;
    margin-bottom: 50px; }

.widget-title-two {
    display: inline-block;
    background: #dfe4e7;
    margin-bottom: -30px;
    margin-left: 20px;
    position: absolute;
    left: 0;
    top: -15%; }

.dt-tag {
    &.widget-bg {
        padding: 60px 20px 20px;

        a {
            padding: 8px 18px; } } }


/* Responsive */
@media screen and (max-width: 1024px) {
  .post-nav {
    li {
      a {
        padding: 10px 16px; } } } }
