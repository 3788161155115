#domain {
  background: #303030;
  position: relative;
  padding: 20px 0;
  z-index: 999;

  &.domain-two {
    padding: 70px 0; } }


.domain {
  background: #eeeeee;
  height: 160px;
  width: 160px;
  padding: 45px 20px;
  position: absolute;
  top: -47px;
  z-index: 10;

  p {
    margin-bottom: 0; }

  h2 {
    font-size: 30px;
    color: #0e0e0e;
    font-weight: 700;
    margin: 0; } }

.domain-price {
  margin-left: 180px; }
.price {
  margin: 0;
  padding: 5px 0;
  list-style: none;
  border-bottom: 1px solid #383838;

  li {
    display: inline-block;
    margin-right: 20px;
    font-weight: 300;
    color: #FFF;
    position: relative;

    &:after {
      position: absolute;
      content: '/';
      right: -15px; }

    &:last-child {
      &:after {
        display: none; } } } }



.domain-details {
  p {
    display: inline-block;
    color: #8b9698;
    padding-right: 20px;
    margin-top: 5px;
    font-size: 13px;
    font-weight: 300; } }


#domain-search-form {
  padding: 10px 0;
  input {
    height: 44px;
    border: 1px solid #575757;
    background: #292929;
    padding: 7px 20px;
    max-width: 320px;
    width: 100%;
    outline: none;
    color: #FFF; }

  &.d-search-form-two {
    margin-top: 33px;
    input {
      max-width: 500px;
      width: 100%; } } }

#select {
  background: $color_theme;
  border: none;
  height: 44px;
  color: #FFF;
  padding: 0 11px 0;
  margin-left: -5px;

  &:hover,&:focus {
    outline: none; } }

.sea-btn {
  border: none;
  height: 44px;
  background: $color_theme;
  color: #FFF;
  padding: 0 20px;
  margin-left: 2px;

  &:hover, &:focus {
    background: #000;
    outline: none; } }

.domain-price-two {
  margin-left: 0;
  p {
    color: #FFF;
    font-size: 20px;
    font-weight: 300; }

  .price {
    border-bottom: none;

    li {
      padding: 7px 32px;
      background: $color_theme;
      font-size: 16px;
      text-align: right;
      margin-right: 0;
      font-weight: 300;

      span {
        font-size: 14px; }

      &:after {
        display: none; } } } }

.domain-search {
  span {
    color: #b3b3b3;
    margin-right: 30px;
    font-size: 13px;
    font-weight: 300;
    position: relative;

    &:after {
      position: absolute;
      content: '/';
      right: -16px;
      top: -1px;
      color: #b3b3b3; } } }

/*=========  Domain Three  ==========*/
#domain {
  &.domain-three {
    background: #FFF;
    padding-top: 100px;

    .domain-search {
      h2 {
        color: #0e0e0e;
        font-size: 30px;
        font-weight: 700; }

      p {
        color: #000;
        text-decoration: underline;
        font-size: 16px;
        font-weight: 300; } }

    #domain-search-form {
      input {
        height: 70px;
        background: #f5f5f7;
        color: #000;
        border-color: #dfe4e7;
        max-width: 850px;
        font-size: 18px; }

      &.d-search-form-two {
        margin-top: 0;
        margin-bottom: 20px; } }

    #select {
      height: 70px;
      padding: 0 21px 0;
      font-size: 17px; }

    .sea-btn {
      height: 70px;
      width: 170px;
      font-size: 17px; }



    .details {
      float: right; } }

  .domain-price-three {
    margin-left: 0;


    .price {
      border-bottom: 0;
      margin-bottom: 50px;

      li {
        padding: 10px 30px;
        background: #eeeeee;
        font-size: 20px;
        color: #0e0e0e;
        font-weight: 400;
        margin-right: 7px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        &:after {
          display: none; }


        &:hover {
          background: $color_theme;
          color: #FFF; } } } } }
.details {
  span {
    color: #6f6f6f;
    margin-right: 30px;
    font-weight: 300;
    position: relative;

    &:after {
      position: absolute;
      content: '/';
      right: -15px; }

    &:last-child {
      margin-right: 0;
      &:after {
        display: none; } } } }


.d-transfer {
  .web-feature {
    h3 {
      color: #FFF; }
    p {
      color: #FFF; } } }




@media screen and (max-width: 1024px) {
  .domain {
    padding: 35px 20px;
    top: -41px; }

  .price {
    li {
      font-size: 12px; } }

  #domain-search-form {
    input {
      width: 235px; }

    &.d-search-form-two {
      input {
        max-width: 380px; } } }

  .domain-price-two {
    .price {
      li {
        padding: 7px 18px; } } }

  #domain {
    &.domain-three {
      #domain-search-form {
        max-width: 70%; }
      .price {
        li {
          padding: 8px 18px; } } } } }




@media screen and (max-width: 768px) {

  .domain {
    height: 120px;
    width: 120px;
    padding: 22px 15px;

    h2 {
      font-size: 20px; } }
  .domain-price {
    margin-left: 160px; }

  #domain-search-form {
    text-align: center; }

  #domain {

    &.domain-three {
      padding: 50px 0;
      #domain-search-form {
        height: 50px;
        text-align: left;
        input {
          width: 50%;
          height: 50px; } }

      #select {
        height: 50px; }

      .sea-btn {
        height: 50px;
        width: 113px;
        padding: 0 15px; }

      .price {
        margin-bottom: 10px;
        li {
          padding: 10px 26px; } }

      .details {
        float: none; } } } }


@media screen and (max-width: 500px) {

  #domain-search-form {
    input {
      width: 175px; }

    #select {
      padding: 0; } }

  .domain-price {
    margin: 40px 0 0; }

  .domain {
    top: -120px;
    left: 50%;
    transform: translateX(-50%); }

  .price {
    li {
      margin-right: 8px;
      &:after {
        right: -9px; } } }

  #domain {
    &.domain-three {
      padding: 50px 0;
      #domain-search-form {
        height: 40px;
        text-align: left;
        input {
          width: 50%;
          height: 40px;
          font-size: 14px; } }

      #select {
        height: 40px;
        font-size: 13px;
        padding: 0; }

      .sea-btn {
        height: 40px;
        width: 87px;
        font-size: 13px;
        padding: 0 12px; }

      .price {
        li {
          padding: 3px 13px;
          font-size: 13px;
          margin-right: 0; } } } }

  .domain-price-two {
    .price {
      li {
        padding: 4px 10px; } } }

  #domain-search-form {
    &.d-search-form-two {
      input {
        max-width: 170px; }

      #select {
        padding: 0; } } } }
