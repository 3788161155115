#web-hosting-feature {
  padding: 100px 0; }

.web-feature {
  text-align: center;
  position:relative {}
  cursor: pointer;

  .b-one, .b-two {
    position: absolute; }

  .b-one {
    right: -123px; }

  .b-two {
    right: -130px; }

  .feature-icon {
    height: 100px;
    width: 100px;
    background: $color_theme;
    border-radius: 50%;
    text-align: center;
    line-height: 100px;
    position: relative;
    margin: 0 auto;
    margin-bottom: 40px;

    &:before {
      position: absolute;
      content: '';
      height: 118px;
      width: 118px;
      border: 10px solid lighten($color_theme, 10%);
      border-radius: 50%;
      left: -9px;
      top: -9px;
      transition: all 0.3s ease-in-out; }

    &:after {
      position: absolute;
      content: '';
      height: 136px;
      width: 136px;
      border: 10px solid lighten($color_theme, 20%);
      border-radius: 50%;
      left: -18px;
      top: -18px; } }

  h3 {
    color: #0e0e0e;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 20px;
    transition: all 0.3s ease-in-out; }

  p {
    font-weight: 300px;
    line-height: 26px; }

  &:hover {
    .feature-icon {
      &:before {
        opacity: 0; } }

    h3 {
      color: $color_theme;
      text-decoration: underline; } } }

@media screen and (max-width: 1024px) {
  .web-feature {
    margin: 0 auto 50px;
    max-width: 500px;

    > img {
      display: none; } } }


@media screen and (max-width: 768px) {
  .web-feature {
    max-width: 450px;
    margin: 0 auto 50px; } }
