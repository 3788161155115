#coming-soon {
  background: $color_theme;
  height: 100vh;
  overflow: hidden; }

@function multiple-box-shadow ($n) {
  $value: '#{random(2000)}px #{random(2000)}px #FFF';
  @for $i from 2 through $n {
    $value: '#{$value} , #{random(2000)}px #{random(2000)}px #FFF'; }

  @return unquote($value); }

$shadows-small: multiple-box-shadow(700);
$shadows-medium: multiple-box-shadow(200);
$shadows-big: multiple-box-shadow(100);

#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $shadows-small;
  animation: animStar 50s linear infinite;

  &:after {
    content: '';
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadows-small; } }

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: $shadows-medium;
  animation: animStar 100s linear infinite;

  &:after {
    content: '';
    position: absolute;
    top: 2000px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $shadows-medium; } }

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: $shadows-big;
  animation: animStar 150s linear infinite;

  &:after {
    content: '';
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $shadows-big; } }


@keyframes animStar {
  from {
    transform: translateY(0px); }
  to {
    transform: translateY(-2000px); } }


.coming-soon {
  transform: translate(-50%,-50%);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;

  .soon-title {
    text-transform: uppercase;
    color: #FFF;
    font-size: 30px; }

  h3 {
    color: #FFF;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 30px; }

  .footer-social-link {
    li {
      border-color: #FFF;

      a {
        i {
          color: #FFF; } }

      &:hover {
        border-color: #000;
        background: #000; } } } }

#coming-date {
  text-align: center;
  position: relative;
  margin-top: 90px;
  margin-left: 100px;
  span {
    font-family: 'PT Sans', sans-serif;
    padding: 5px;
    display: inline-block;
    font-size: 18px;
    color: #FFF;
    margin-right: 95px;
    text-transform: uppercase;
    position: relative;
    z-index: 500;
    padding: 30px;
    &:before {
      position: absolute;
      height: 150px;
      width: 150px;
      background-color: #f89406;
      content: '';
      left: 0;
      top: 0;
      z-index: -1;
      border-radius: 10px;
      transform: rotate(45deg); }

    &:after {
      position: absolute;
      content: '';
      height: 16px;
      width: 60px;
      background: #f89406;
      right: -75px;
      top: 67px; }

    &:last-child:after {
      display: none; }

    p {
      font-family: 'PT Sans', sans-serif;
      font-size: 72px;
      line-height: 60px;
      font-weight: 700; } } }


.comming-timer {
  padding: 90px 0;
  border-top: 1px solid darken($color_theme, 10%);
  border-bottom: 1px solid darken($color_theme, 10%);
  margin-top: 50px;
  margin-bottom: 20px; }

#timer {
  margin-top: 70px;
  display: inline-block;


  div {
    position: relative;
    float: left; }

  .dayes {
    p {
      color: #FFF;
      font-size: 13px;
      position: absolute;
      left: 10px;
      top: -84px;
      text-transform: uppercase; }

    span {
      font-size: 200px;
      color: #FFF; } }

  .hours, .minit {
    margin: -70px 20px 20px;
    position: relative;

    span {
      color: #000;
      font-size: 80px; }
    p {
      color: #000;
      font-size: 14px;
      text-transform: uppercase;
      margin-left: -20px;
      margin-bottom: 15px; } }

  .secounds {
    float: none;
    margin-right: 100px;
    span {
      font-size: 40px;
      color: #FFF; }

    .sec {
      color: #000;
      font-size: 14px; } } }


@media screen and (max-width: 1024px) {

  #timer {
    .dayes {
      span {
        font-size: 120px; }

      p {
        top: -55px; } }

    .hours ,.minit {
      margin: -42px 10px 10px;
      span {
        font-size: 40px; }

      p {
        margin: 0; } }

    .secounds {
      margin-right: 18px; } } }

@media screen and (max-width: 768px) {
  #coming-soon {
    padding: 0 0; }

  .coming-soon {
    h3 {
      font-size: 14px;
      margin-bottom: 10px; } }

  .comming-timer {
    padding: 10px 0;
    margin-top: 20px; }

  #timer {
    margin-top: 35px;
    .dayes {
      span {
        font-size: 70px; }

      p {
        top: -38px; } }

    .hours, .minit {
      margin: -38px 10px 0;

      span {
        font-size: 28px; } }

    .secounds {
      margin-right: 25px;

      span {
        font-size: 20px; } } } }
