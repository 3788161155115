#error-page {
  padding: 150px 0;

  img {
    width: 100%; } }

.error-title {
  font-size: 40px;
  letter-spacing: 16px;
  color: $color_theme;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 50px; }

.subscribe {
  h3 {
    font-size: 30px;
    font-weight: 600;
    color: #000;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 40px; }

  p {
    margin-bottom: 30px;
    color: #757575;
    font-weight: 300; }

  .dt-btn {
    padding: 14px 37px; } }

.subscribe-form {
  input {
    max-width: 460px;
    width: 100%;
    height: 55px;
    border: 1px solid #dddbdb;
    outline: none;
    padding: 10px;
    margin-bottom: 20px; }

  .dt-btn {
    height: 55px;
    border: none;
    padding: 0;
    width: 70px;
    margin-left: -3px;

    i {
      margin-left: 0;
      font-size: 16px;
      position: relative;
      opacity: 1;
      right: auto;
      transform: translateX(0); } } }

@media screen and (max-width: 768px) {
  .subscribe-form {
    input {
      width: 400px; } }

  .subscribe {
    p {
      br {
        display: none; } }

    .dt-btn, .bgt {
      padding: 10px 28px; }

    .bgt {
      padding: 9px 28px !important; } } }


@media screen and (max-width: 500px) {

  .subscribe-form {
      input {
        width: 260px; } } }
