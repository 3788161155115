// Fonts
$font_primary: 'Montserrat', sans-serif;


// Color
$color_theme: #2da15e;
$color_front: #111;
$color_theme-2: #99cc33;
$color_theme-3: #ffc600;
$color_theme-4: #00ccff;
$color_theme-5: #f71d4b;
$color_text: #6d6c6c;
$color_header: #2c3e50;
$color_rgba: rgba(255, 177, 0, 0.8);
$color_rgba-2: rgba(0, 0, 0, 0.8);
