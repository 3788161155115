#team {
  padding: 100px 0;
  position: relative; }


.team-slider {
  position: relative;

  .swiper-container {
    width: 90%; } }


.member-details {
  position: absolute;
  top: 0;
  right: 0; }

.member-details {
  h3 {
    color: $color_theme;
    font-weight: 700;
    font-size: 20px;
    border-bottom: 1px solid $color_theme;
    margin-bottom: 5px; }

  p {
    color: #5f5f5f; } }

.team-profail {
  margin: 0;
  padding: 25px 0 0 50px;
  list-style: none;

  li {
    padding: 25px 0;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      width: 1px;
      height: 40px;
      background: #dfe4e7; }

    &:before {
      top: -20px;
      left: 6px; }

    a {
      font-size: 16px;
      color: #0e0e0e;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: $color_theme; } } } }

.swiper-pagination-bullet {
  display: block;
  margin: 18px 0;
  height: 10px;
  width: 10px;
  border-radius: 0;
  position: relative;
  margin-right: 20px;

  &:first-child {
    margin-top: 0; }

  &:after {
    position: absolute;
    content: '';
    border: 1px solid #000;
    height: 14px;
    width: 14px;
    left: -2px;
    top: -2px; } }

.swiper-pagination-bullet-active {
  background-color: $color_theme;
  &:after {
    border-color: $color_theme; } }

.slider-control {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);

  .banner-prev, .banner-next {
    height: 44px;
    width: 55px;
    background: $color_theme;
    line-height: 44px;
    text-align: center;
    color: #FFF;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      background: #333; } }

  .banner-next {
    margin-bottom: 8px; } }

.swiper-pagination {
  right: 50px; }

/*=========  Salse Team  ==========*/
.salse-man {
  margin-bottom: 40px;

  .member-img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: 2px solid #e3e8ea;
    padding: 8px;
    position: relative;
    margin: 0 auto 30px;

    img {
      border-radius: 50%;
      width: 100%; }

    .member-profile {
      position: absolute;
      height: 94%;
      width: 94%;
      left: 8px;
      top: 8px;
      right: 8px;
      bottom: 8px;
      background: rgba(0,0,0,0.5);
      border-radius: 50%;
      margin: auto;
      opacity: 0;
      transition: all 0.3s ease-in-out;

      .link {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
        transform: translate(-25%,-50%);


        li {
          display: inline-block;
          margin-right: 5px;
          height: 35px;
          width: 35px;
          border-radius: 50%;
          text-align: center;
          line-height: 32px;
          border: 2px solid #FFF;
          border-radius: 50%;
          transition: all 0.3s ease-in-out;

          a {
            color: #FFF; }

          &:hover {
            background: $color_theme;
            border-color: $color_theme;
            a {
              color: #FFF;
              display: block; } } } } } }

  &:hover {
    .member-profile {
      opacity: 1; } }

  .memeber-details {
    padding: 15px;
    text-align: center;
    background: #f5f5f7;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

    h3 {
      font-size: 20px;
      font-weight: 700;
      color: #0e0e0e;
      margin-top: 0; }

    h5 {
      font-size: 14px;
      color: #5f5f5f;
      text-decoration: underline;
      margin-bottom: 0; } } }


@media screen and (max-width: 1024px) {

  .team-slider {
    .swiper-container {
      width: 96%; } }

  .team-slider {
    .team-thumb {
      max-width: 320px;

      img {
        width: 100%; } } }
  .member-details {
    right: 0px; }

  .team-profail {
    padding: 15px 0 0 60px;

    li {
      padding: 15px 0;

      &:before {
        height: 25px;
        top: -13px; } } }

  .salse-man {
    .member-img {
      .member-profile {
        height: 92%;
        width: 92%;
        left: 9px;
        right: 9px;

        .link {
          transform: translate(-30%, -50%); } } } } }


@media screen and (max-width: 768px) {
  .team-slider {
    .swiper-container {
      width: 70%; } }

  .member-details {
    right: 70px; }

  .slider-control {
    right: 15px; } }


@media screen and (max-width: 500px) {
  .team-slider {
    .swiper-container {
      width: 85%; }
    .team-thumb {
      max-width: 200px; } }

  .slider-control {
    display: none; }

  .member-details {
    right: 0;

    h3 {
      font-size: 13px; } }

  .team-profail {
    padding: 0px 0 0 60px;
    li {
      padding: 3px 0;

      &:before {
        height: 13px;
        top: -8px; } } } }
