#support-center {
  padding: 100px 0; }

.supp-feature-item {
  text-align: center;

  .fea-icon {
    background: #f5f5f7;
    width: 100%;
    height: 135px;
    text-align: center;
    border-top-left-radius: 270px;
    border-top-right-radius: 270px;
    padding: 50px 0; }

  h3 {
    font-size: 18px;
    font-weight: 700;
    color: #0e0e0e;
    margin-bottom: 10px; }

  p {
    font-weight: 300;
    margin-bottom: 30px;
    color: #5f5f5f; }

  .dt-btn {
    padding: 13px 29px; } }

@media screen and (max-width: 992px) {
  .supp-feature-item {
    max-width: 280px;
    margin-bottom: 30px; } }

@media screen and (max-width: 768px) {
  .supp-feature-item {
    margin: 0 auto;
    margin-bottom: 30px; } }
