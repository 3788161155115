#team-two {
  padding: 100px 0;
  background: #f5f5f5; }

.team-member {
  position: relative;
  max-width: 400px;
  overflow: hidden;


  .member-thumb {
    img {
      width: 100%; } }

  .member-details {
    position: absolute;
    left: 0;
    top: 80%;
    background: rgba($color_theme, 0.7);
    height: 100%;
    width: 100%;
    padding: 20px 40px;
    transition: all 0.3s ease-in-out;

    .name {
      color: #fff;
      text-transform: uppercase;
      font-size: 26px;
      font-weight: 700;
      border-bottom: none;
      margin-top: 0; }


    .position {
      color: #0e0e0e;
      text-decoration: underline;
      margin-top: 0;
      margin-bottom: 20px; }

    p {
      color: #FFF; } }

  .member-profile {
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    right: 5px;
    top: -30%;
    transition: all 0.5s ease-in-out;

    li {
      a {
        height: 40px;
        width: 40px;
        line-height: 40px;
        background: #000;
        display: block;
        text-align: center;
        margin-bottom: 2px;

        i {
          color: #FFF; }

        &:hover {
          background: #FFF;

          i {
            color: #000; } } } } }

  &:hover {
    .member-details {
      top: 0;

      .name {
        margin-top: 200px; } }

    .member-profile {
      top: 0; } } }


.team-three {
  margin-bottom: 30px; }

/* Responsive */
@media screen and (max-width: 1024px) {

  .team-member {
    .member-details {
      top: 75%; }

    .member-profile {
      top: -35%; }

    &:hover {
      .member-details {
        .name {
          margin-top: 120px; } } } } }

@media screen and (max-width: 768px) {
  #team-two {
    padding: 50px 0; }

  .team-member {
    margin: 0 auto 30px;

    .member-details {
      top: 83%; } } }

@media screen and (max-width: 500px) {
  .team-member {


    .member-details {
      top: 78%; } } }
