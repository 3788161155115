#counter {
  padding: 100px 0;
  background-size: cover; }

.counter-item {
  text-align: center;
  cursor: pointer;
  .count-icon {
    max-height: 260px;
    max-width: 260px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.502);
    position: relative;
    line-height: 250px;
    transition: all 0.3s ease-in-out;

    img {
      position: relative;
      z-index: 999; }

    &:before {
      position: absolute;
      content: '';
      left: -5px;
      top: -5px;
      border: 5px solid #FFF;
      height: 260px;
      width: 264px;
      border-radius: 50%;
      transition: all 0.15s ease-in-out; }

    &:after {
      position: absolute;
      content: '';
      height: 170px;
      width: 170px;
      border-radius: 50%;
      margin: 0 auto;
      background: #141515;
      line-height: 170px;
      left: 41px;
      top: 41px;
      z-index: 1;
      transition: all 0.15s ease-in-out; } }

  h2 {
    font-size: 40px;
    color: #FFF; }


  h3 {
    color: #FFF;
    font-size: 16px;
    margin: 0; }

  &:hover {
    .count-icon {

      &:before {
        border: 7px solid $color_theme; }

      &:after {
        background: $color_theme; } } } }


@media screen and (max-width: 1280px) {
  .counter-item .count-icon {
    max-height: 215px;
    max-width: 215px;
    margin: 0 auto;

    img {
      vertical-align: baseline; }

    &:before {
      height: 220px;
      width: 220px; }

    &:after {
      height: 150px;
      width: 150px;
      left: 30px;
      top: 30px; } } }

@media screen and (max-width: 992px) {
  .counter-item {
    margin-bottom: 30px;
    .count-icon {
      max-height: 215px;
      max-width: 215px;
      margin: 0 auto; }

    img {
      vertical-align: baseline; }

    &:before {
      height: 220px;
      width: 220px; }

    &:after {
      height: 150px;
      width: 150px;
      left: 30px;
      top: 30px; } } }

@media screen and (max-width: 500px) {
  .full-wide {
    width: 100%; } }
