#blog-grid {
  padding: 100px 0; }

.blog {
  border: 1px solid #dfe4e7;
  margin-bottom: 30px;
  max-width: 450px;
  margin: 0 auto 30px;


  img {
    width: 100%; } }

.blog-content {
  padding: 30px 15px;
  .blog-title {
    font-size: 18px;
    color: #000;
    line-height: 26px;
    margin-top: 0;
    a {
      color: #000;

      &:hover {
        color: $color_theme;
        text-decoration: underline; } } }



  p {
    margin-bottom: 30px;
    color: #5f5f5f;
    font-weight: 300; }

  .dt-btn {
    padding: 9px 26px;

    &:hover {
      padding: 9px 37px 9px 26px; } } }

.post-meta {
  margin-bottom: 20px;
  a {
    display: inline-table;
    margin-right: 30px;
    font-size: 13px;
    color: #000;
    font-weight: 300;

    i {
      margin-right: 5px;
      color: $color_theme; }

    &:hover {
      color: $color_theme; } } }

/*=========  Blog Three  ==========*/
.blog-three {
  .blog-thumb {
    img {
      width: 100%; } }

  .blog-content {
    background: #f5f5f5;
    padding: 15px;
    margin-top: 20px; } }

.blog-three-small {
  margin-bottom: 16px;
  .blog-thumb {
    float: left;
    height: 130px;
    width: 240px;

    img {
      width: 100%; } }

  .blog-content {
    margin-left: 250px;
    padding: 10px 15px;

    h3 {
      margin-top: 0;

      a {
        font-size: 15px;
        color: #000; } }

    .post-meta {
      a {
        margin-right: 20px;
        font-weight: 400;
        position: relative;

        &:after {
          position: absolute;
          content: '';
          right: -13px;
          top: 3px;
          height: 20px;
          width: 2px;
          background: #5f5f5f;
          height: 13px; }

        &:last-child {
          &:after {
            display: none; } } } } } }

/*=========  Blog Full Width  ==========*/
#news-full-width {
  padding: 100px 0; }

.news-full {
  margin-bottom: 30px;
  .news-thumb {
    img {
      width: 100%; } }

  .news-content {
    background: #f5f5f7;
    padding: 40px;
    position: relative;
    margin-top: 10px;

    h2 {
      margin-bottom: 20px;
      margin-top: 0;
      a {
        color: #0e0e0e;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 24px;

        &:hover {
          color: $color_theme; } } }

    p {
      margin-bottom: 25px;
      color: #5f5f5f; }

    .dt-btn {
      padding: 10px 25px; }

    .post-meta {
      margin: 0;
      padding: 0;
      list-style: none;
      position: absolute;
      right: 50px;
      top: -45px;

      li {
        display: inline-block;
        background: $color_theme;
        padding: 15px 32px;
        margin-right: -4px;

        &:last-child {
          background: #000; }

        h6 {
          font-size: 36px;
          color: #fff;
          margin-top: 0;
          margin-bottom: 10px;
          line-height: 26px; }

        span {
          color: #FFF;
          font-size: 13px;
          display: block;
          line-height: 13px; } } } } }


.page-navigation {
  .page-nav {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      height: 40px;
      width: 40px;
      line-height: 40px;
      border: 1px solid #dfe4e7;
      text-align: center;
      margin-right: 8px;
      transition: all 0.3s ease-in-out;

      &:last-child {
        width: auto;
        border: none;

        &:hover {
          background: transparent;
          a {
            color: $color_theme; } } }

      a {
        display: block;
        color: #0e0e0e;

        i {
          margin-left: 5px; } }

      &:hover {
        background: $color_theme;

        a {
          color: #FFF; } } } } }

/*=========  Blog Three  ==========*/
.news-full-two {
  .news-content {
    h2 {
      a {
        font-size: 22px;
        text-transform: capitalize; } }

    .post-meta {
      top: -55px; } } }



/*=========  News Single  ==========*/
#news-single {
  padding: 100px 0;

  .news-content {
    background: transparent;
    padding: 0;
    margin-top: 30px;

    .post-meta {
      top: -80px; }

    .blockquote {
      padding: 50px;
      background: #f5f5f5;
      border-left: none;
      position: relative;
      margin-bottom: 40px;

      p {
        font-size: 16px;
        color: #5f5f5f;
        font-style: italic;
        margin-bottom: 0;

        span {
          color: $color_theme; } }

      img {
        position: absolute;
        bottom: -25px;
        right: 40px; } } } }

.about-author {
  background: #f5f5f7;
  position: relative;
  padding: 40px 20px 20px;
  margin: 60px 0;

  h3 {
    margin-left: 125px;
    margin-bottom: 0;
    color: #0e0e0e;
    font-size: 18px;
    display: inline-block; }


  p {
    margin-bottom: 0;
    color: #5f5f5f;
    font-weight: 300; } }

.author-img {
  position: absolute;
  border: 1px solid #dfe4e7;
  padding: 4px;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  top: -35px;

  img {
    border-bottom-left-radius: 50%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%; } }



.author-profile {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  top: 10px;
  right: 30px;
  border-bottom: 1px solid #dfe4e7;

  li {
    display: inline-block;
    margin-right: 10px;

    &:last-child {
      margin-right: 0; }
    a {
      color: #0e0e0e;

      &:hover {
        color: $color_theme; } } } }


/*=========  Comments  ==========*/

ul {
  margin: 0;
  padding: 0;
  list-style: none; }


.comment-area {
  h5 {
    font-size: 14px;
    color: #0e0e0e;
    text-transform: uppercase;
    text-decoration: underline;
    margin-top: 0; }

  h3 {
    color: #0e0e0e;
    font-size: 26px;
    margin-top: 0;
    margin-bottom: 70px; } }


.post-comment {
    margin: 40px 0; }

.comment-content {
    background: #fafafa;
    padding: 30px 20px 20px;
    border-radius: 5px;
    position: relative; }

.post-comment {
    .avatar {
      padding: 4px;
      border-bottom-left-radius: 50%;
      border-top-left-radius: 50%;
      border-top-right-radius: 50%;
      top: -35px;
      border: 1px solid #e3e8ea;
      display: inline-block;
      position: absolute;
      left: 30px;
      top: -25px;


      img {
        border-bottom-left-radius: 50%;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%; } } }

.post-comment {

    .avatar {
        img {
            width: 74px;
            height: 74px; } } }


.post-comment {
    .post-body {
        overflow: hidden; } }

.post-comment {
    .comment-header {
        line-height: 1;
        font-size: 13px;

        margin-top: 3px;
        margin-bottom: 10px; } }


.post-comment {
    .author {
      margin-left: 110px;
      a {
        font-size: 16px;
        font-weight: 700;
        color: #191a1c; } } }

.post-comment {
    .comment-header {
        .time-ago {
          float: right;
          display: inline-block;

          a {
              font-size: 13px;
              color: #0e0e0e;
              margin-left: 6px; } } } }

.post-comment {
    .reply {
        float: right;

        a {
          color: #FFF;
          padding: 2px 18px;
          background: #000;
          display: block;
          font-size: 12px;
          font-weight: 300;

          &:hover {
            background: $color_theme; } } } }


.post-comment {
    .reply-comment {
        padding-left: 90px; } }

.post-comment {
    .reply-comment {
        .avatar {
            img {
                width: 60px;
                height: 60px; } } } }


.form-leave-comment {
    width: 100%;
    float: left;
    margin-bottom: 100px; }

.form-leave-comment {
    h5 {
        margin-left: 5px; } }

.form-leave-comment {
  form {
    margin-top: 30px;

    .dt-btn {
      border: none;
      transition: all 0.3s ease-in-out;
      background: #000;
      padding: 12px 40px;

      &:hover {
        background-color: $color_theme; } } } }

.form-leave-comment {
    input, textarea {
        padding: 14px 18px;
        margin: 0;
        width: 100%;
        max-width: 100%;
        border: 0px;
        font-weight: 300;
        font-size: 13px;
        line-height: 22px;
        color: #555;
        width: 100%;
        margin-bottom: 14px;
        background-color: #f5f5f5;
        border: none;
        border-radius: 4px;
        outline: none; }

    textarea {
        height: 150px; } }

@media screen and (max-width: 1024px) {

  .blog-three-small {
    .blog-content {
      margin-left: 235px;
      h3 {
        a {
          font-size: 12px; } }

      .post-meta {
        margin-bottom: 0; } } } }

@media screen and (max-width: 768px) {
  #blog-grid {
    padding: 50px 0; }

  .blog {
    margin-bottom: 30px; }

  .blog-three {
    margin-bottom: 20px; }

  #news-full-width {
    padding: 50px 0; }

  .news-full {
    .news-content {
      padding: 20px;
      .post-meta {
        li {
          padding: 7px 18px;

          h6 {
            font-size: 26px;
            line-height: 20px; } } } } } }

@media screen and (max-width: 500px) {
  .blog-three-small {
    .blog-thumb {
      float: none; }

    .blog-content {
      margin-left: 0; } } }
