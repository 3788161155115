// // DO NOT EDIT DIRECTLY!
////Generated by gulpfile.js/tasks/iconFont.js
////from gulpfile.js\tasks\iconFont\template.sass

@font-face {
  font-family: isco-build-icons;
  src: url("../fonts/isco-build-icons.eot");
  src: url("../fonts/isco-build-icons.eot?#iefix") format('embedded-opentype'), url("../fonts/isco-build-icons.woff") format('woff'), url("../fonts/isco-build-icons.ttf") format('truetype'), url("../fonts/isco-build-icons.svg#isco-build-icons") format('svg');
  font-weight: normal;
  font-style: normal; }

@mixin icon($content: '') {
  &:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    content: $content;
    font-family: 'isco-build-icons';
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
    @content; } }

.icon {
  @include icon; }

// Save variable
$icon-chat-icon: "\EA01";
// Save mixin
@mixin icon--chat-icon {
  @include icon($icon-chat-icon) {
    @content; } }
// Expose as class
.icon.-chat-icon:before {
  content: $icon-chat-icon; }

// Save variable
$icon-email-icon: "\EA02";
// Save mixin
@mixin icon--email-icon {
  @include icon($icon-email-icon) {
    @content; } }
// Expose as class
.icon.-email-icon:before {
  content: $icon-email-icon; }

// Save variable
$icon-facebook: "\EA03";
// Save mixin
@mixin icon--facebook {
  @include icon($icon-facebook) {
    @content; } }
// Expose as class
.icon.-facebook:before {
  content: $icon-facebook; }

// Save variable
$icon-instagram: "\EA04";
// Save mixin
@mixin icon--instagram {
  @include icon($icon-instagram) {
    @content; } }
// Expose as class
.icon.-instagram:before {
  content: $icon-instagram; }

// Save variable
$icon-search-icon: "\EA05";
// Save mixin
@mixin icon--search-icon {
  @include icon($icon-search-icon) {
    @content; } }
// Expose as class
.icon.-search-icon:before {
  content: $icon-search-icon; }

// Save variable
$icon-telephone-icon: "\EA06";
// Save mixin
@mixin icon--telephone-icon {
  @include icon($icon-telephone-icon) {
    @content; } }
// Expose as class
.icon.-telephone-icon:before {
  content: $icon-telephone-icon; }

// Save variable
$icon-twitter: "\EA07";
// Save mixin
@mixin icon--twitter {
  @include icon($icon-twitter) {
    @content; } }
// Expose as class
.icon.-twitter:before {
  content: $icon-twitter; }

// Save variable
$icon-viget: "\EA08";
// Save mixin
@mixin icon--viget {
  @include icon($icon-viget) {
    @content; } }
// Expose as class
.icon.-viget:before {
  content: $icon-viget; }

