#question {
  padding: 100px 0; }

.questions-wrapper {
  width: 90%;
  margin: 0 auto; }

.panel-group {
  margin-top: 20px;
  .panel {
    margin-bottom: 20px;
    border-radius: 0;
    border: none; } }
.panel {
  border-radius: 0;
  border: none; }

.panel-body {
  background: #f5f5f7;
  p {
    margin-bottom: 0; } }

.panel-default {
  text-align: left;
  > .panel-heading {

    color: #000;
    text-decoration: none;
    border: none;
    border-radius: 0;

    a {
      font-size: 15px;
      padding: 17px;

      &:hover, &:focus {
        text-decoration: none; } }

    &.active {
      background-color: $color_theme;

      a {
        color: #FFF; } } } }

.panel-heading {
  padding: 0;
  border-radius: 0;

  .accordion-toggle:after {
    font: normal normal normal 14px/1 FontAwesome;
    content: "\f107";
    float: right;
    color: #000;
    font-size: 20px; } }

.panel-heading {
  .accordion-toggle {
    display: block;

    &.collapsed {
      background: #eee;

      &:after {
        content: "\f105"; } } } }

.questions {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    padding: 10px 0;

    span {
      font-weight: 700;
      margin-right: 10px;
      display: block;
      float: left; }

    .content {
      margin-left: 30px;
      color: #5f5f5f;
      line-width: 300;
      line-height: 26px; } } }

/*=========  Questions  ==========*/
.ques-title {
  margin-bottom: 70px;
  h3 {
    color: #FFF;
    font-weight: 700;
    color: #0e0e0e;
    font-weight: 26; }

  h5 {
    color: #0e0e0e;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 16px;
    text-decoration: underline; } }

.qes {
  border-bottom: 1px solid #dfe4e7;
  img {
    float: left;
    max-width: 240px; }


  .content {
    margin-left: 255px;
    p {
      font-size: 13px;
      font-weight: 300;
      line-height: 24px;
      margin-bottom: 30px; }

    ul {
      margin-bottom: 30px;
      padding: 0;
      list-style: none;

      li {
        color: #0e0e0e;
        line-height: 35px;

        i {
          margin-right: 5px; } } }

    .dt-btn {
      padding: 12px 37px;
      margin-top: 20px; } } }
.panel-group {
  &.qus-two {
    .panel {
      margin-bottom: 14px; } } }


.faq-title {
  h3 {
    color: #0e0e0e;
    font-size: 26px;
    font-weight: 700; } }

.questions-wrapper-two {
  width: 100%; }

/*=========  Domain Registation  ==========*/

.regi-ques {
  .panel-body {
    padding: 20px;
    .content {
      line-height: 26px;
      font-weight: 300;
      color: #5f5f5f; } } }


@media screen and (max-width: 768px) {
  #question {
    padding: 50px 0; } }


@media screen and (max-width: 500px) {
  #question {
    padding: 50px 0; }
  .qes {
    img {
      float: none;
      margin-bottom: 15px; }

    .content {
      margin-left: 0;

      .dt-btn {
        margin-top: 0;
        margin-bottom: 20px; } } }

  .panel-default {
    > {
      .panel-heading {
        a {
          font-size: 12px; } } } }

  .faq-cate {
    margin-bottom: 20px; } }
