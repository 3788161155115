#testimonial, #testimonial-two, #testimonial-three {
  padding: 70px 0;
  position: relative;

  &.testimonial-sec {
    padding: 150px 0 50px; } }

.testmonial {
  position: relative;

  .swiper-pagination-bullet {
    background: rgba(255,255,255,0.302);
    opacity: 1;

    &:first-child {
      margin-top: 0; }

    &:after {
      border: 1px solid rgba(255,255,255,0.302); } }

  .swiper-pagination-bullet-active {
    background: $color_theme;
    &:after {
      border-color: $color_theme; } }


  .slider-control {
    right: 0;
    top: 60px; } }

.testimonial-slider {
  width: 88%; }

.slider-content {
  padding: 20px 60px 20px 170px;
  background: rgba(0,0,0,0.1);
  border: 1px solid #202325;
  position: relative;
  border: 1px solid rgba(255,255,255,0.1);

  p {
    color: #FFF;
    line-height: 26px; }

  img {
    position: absolute;
    right: 15px;
    bottom: 10px; }

  span {
    font-size: 150px;
    color: #2da15e;
    position: absolute;
    top: 22px;
    right: 0px; } }

.user-details {
  // position: absolute
  left: 0;
  top: 50px;
  display: inline-block;

  .testi-thumb {
    width: 120px;
    height: 120px;
    border: 6px solid rgba(255,255,255,0.1);
    float: left;
    margin-top: -65px;
    margin-left: 20px;
    position: relative;

    img {
      width: 100%; } }

  .name {
    float: right;
    margin-left: 20px;

    h3 {
      color: #FFF;
      font-size: 15px;
      margin-top: 20px;
      margin-bottom: 5px; }
    p {
      color: lighten($color_theme, 15%);
      margin-bottom: 0;
      font-size: 12px;
      font-weight: 300; } } }


/*=========  Testimonial Two  ==========*/
.testimonial-two {
  .testimonial-slider {
    width: 100%; }

  .slider-content {
    padding: 20px 20px 20px 78px;
    margin-left: 60px;

    p {
      margin-bottom: 0; } }

  .user-details {
    .testi-thumb {
      border-top-left-radius: 50%;
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
      img {
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%; } } }

  .user-details {
    .testi-thumb {
      top: -55px;
      left: -19px; }

    .name {
      float: none;
      margin-left: 0;
      position: absolute;
      bottom: 10px;

      h3 {
        margin-top: 0; } } } }

.slider-pagination {
  .swiper-pagination-bullet {
    display: inline-block; }

  .swiper-pagination {
    right: auto;
    left: 50%;
    transform: translateX(-50%); } }

/*=========  Testimonial Three  ==========*/
.test-slider-three {
  .slider-content {
    padding: 20px;

    p {
      max-width: 750px; } }

  .user-details {
    float: right;

    .testi-thumb {
      border-radius: 50%;
      float: right;
      margin-right: 30px;


      img {
        border-radius: 50%; } }

    .name {
      float: left;

      h3 {
        margin-top: 10px; } } } }


/*=========  Testimonial Four  ==========*/

.testi-tab {
  .tabs {
    background-color: transparent;
    float: left;
    li {
      margin-right: 0;
      border-right: none;
      a {
        height: 120px;
        width: 120px;
        border-radius: 50%;
        padding: 4px;
        background: #FFF;
        border: 1px solid $color_theme;

        img {
          border-radius: 50%;
          height: 110px;
          -webkit-filter: blur(1px);
          filter: blur(1px); }

        &:after {
          display: none; } }


      &.current {

        a {
          height: 140px;
          width: 140px;
          background: #FFF;

          img {
            height: 130px;
            width: 130px;
            -webkit-filter: blur(0px);
            filter: blur(0px); } } } } }

  .tab_content {
    margin-top: 0px;
    margin-left: 270px; } }


/*=========  Testimonial Five  ==========*/
.testimonial-five {
  margin-top: 100px; }

.testi-five {
  margin-bottom: 50px;
  .client-img {
    height: 140px;
    width: 140px;
    border-radius: 50%;
    padding: 4px;
    border: 1px solid #cfcfcf;
    float: left;

    img {
      height: 130px;
      width: 130px;
      border-radius: 50%; } }

  .client {
    margin-left: 160px;

    h3 {
      margin-top: 0;
      color: #0e0e0e;
      font-size: 16px;
      font-weight: 600;
      transition: all 0.3s ease-in-out; }

    h6 {
      text-decoration: underline;
      font-size: 14px;
      font-weight: 300; }

    p {
      font-weight: 300;
      line-height: 26px; } }

  &:hover {
    .client {
      h3 {
        color: $color_theme; } } } }

/*=========  Testimonial Six  ==========*/
.testi-six {
  text-align: center;
  background: #f5f5f7;
  padding: 40px 20px 20px;
  margin-bottom: 80px;
  transition: all 0.3s ease-in-out;

  .client-img {
    height: 100px;
    width: 100px;
    padding: 4px;
    border-radius: 50%;
    border: 1px solid #000;
    position: absolute;
    left: 50%;
    top: -50px;
    transform: translateX(-50%);

    img {
      height: 90px;
      width: 90px;
      border-radius: 50%; } }

  .client {
    h3 {
      margin-top: 35px;
      color: #0e0e0e;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0; }

    h5 {
      text-decoration: underline;
      font-weight: 300; }

    p {
      color: #5f5f5f;
      font-weight: 300; } }

  &:hover {
    background: #fff;
    box-shadow: 0 10px 20px rgba(50,50,50,0.1); } }

/* Responsive */

@media screen and (max-width: 768px) {

  .testimonial-slider {
    width: 86%; }

  .slider-content {
    padding: 20px 60px 20px 90px; }

  .user-details {
    .testi-thumb {
      height: 80px;
      width: 80px;
      margin-left: 5px;
      margin-top: -40px; } }


  .testimonial-two {
    .slider-content {
      padding: 20px 10px 40px 20px; }

    .user-details {
      .testi-thumb {
        top: 8px;
        left: 54px; }

      .name {
        margin-left: 145px;
        bottom: -5px; } } }

  .testi-six {
    max-width: 400px;
    margin: 0 auto 70px; } }

@media screen and (max-width: 500px) {

  .testimonial-slider {
    width: 100%; }

  .slider-content {
    padding: 20px 10px 20px 10px; }

  .user-details .testi-thumb {
    top: 20px; } }
