#footer {
  padding-top: 60px; }
.footer-contact {
  margin-bottom: 50px;
  img {
    margin-bottom: 20px; }

  p {
    color: #FFF;
    margin-bottom: 0; }

  span {
    color: #c2c2c2; } }

.contact-info {
  margin: 30px 0;

  .info {
    width: 50%;
    float: left; } }

.footer-title {
  margin-top: 0;
  color: #FFF;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 36px; }

.footer-link {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    padding: 5px 0;

    a {
      color: #FFF;
      font-size: 13px;
      font-weight: 300;
      position: relative;

      i {
        margin-right: 10px; }

      &:hover {
        color: $color_theme;

        &:after {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          background: $color_theme;
          height: 1px;
          content: ''; } } } } }



.copyright {
  padding: 22px 0;
  border-top: 1px solid $color_theme; }

.footer-social-link {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    height: 35px;
    width: 35px;
    border: 2px solid #3d3e3e;
    display: inline-block;
    margin-right: 10px;
    text-align: center;
    line-height: 32px;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;

    a {
      color: #3d3e3e;
      display: block; }


    &:hover {
      background: $color_theme;
      border-color: transparent;

      a {
        color: #FFF; } } } }

.copyright-text {
  color: #6e6f70;
  font-weight: 300;
  font-size: 13px;
  margin-bottom: 0;
  margin-top: 8px;

  a {
    color: $color_theme; } }
