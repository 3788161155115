#topic {
  padding: 100px 0; }


.tpoics {
  .topic-item {
    margin-bottom: 30px;
    padding: 25px 20px;
    border: 1px solid #dfe4e7;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    h3 {
      color: #0e0e0e;
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 15px;
      margin-top: 0;
      transition: all 0.3s ease-in-out; }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        line-height: 30px;
        color: #0e0e0e;
        font-size: 13px;

        i {
          margin-right: 5px;
          color: $color_theme; } } }

    &:hover {
      border-color: $color_theme;

      h3 {
        color: $color_theme;
        text-decoration: underline; } } } }


