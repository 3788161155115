// +font-face(OpenSans, '../fonts/OpenSans-Regular-webfont')
// +font-face(OpenSans, '../fonts/OpenSans-Bold-webfont', bold)

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 26px; }

.no-margin {
  margin: 0; }

.starter-template {
  padding: 40px 15px;
  text-align: center; }

.sprite {
  display: inline-block;
  width: 32px;
  margin: 10px;

  &:hover {
    opacity: 0.8; } }

.icon {
  font-size: 32px;
  margin: 10px; }

.screenreader {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important; }

svg {
  display: block; }
a {
  transition: all 0.15s ease-in-out; }
a,
a:hover {
  text-decoration: none; }


.dt-btn-two {
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 13px;
  font-weight: 700;
  line-height: 48px;
  margin-top: 30px;
  max-width: 190px;
  text-align: center;
  position: relative;
  text-transform: uppercase;
  vertical-align: middle;
  width: 100%;
  border: 1px solid #fff;
  background: transparent;
  background: rgba(255,255,255,0.1);
  border: 1px solid rgba(255,255,255,0.3);
  transition: all 0.3s ease-in-out;

  i {
    position: absolute;
    right: 30px;
    top: 18px;
    transition: all 0.3s ease-out; }

  svg {
    height: 50px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

  rect {
    fill: none;
    stroke: #fff;
    stroke-width: 4;
    stroke-dasharray: 102, 1, 0;
    transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1); }

  &:hover, &:focus {
    color: #FFF;
    text-decoration: none;

    i {
      right: 25px; }

    rect {
      stroke-width: 4;
      stroke-dasharray: 28, 168, 121, 0;
      stroke-dashoffset: 36;
      transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1); } } }

.dt-btn {
  padding: 14px 37px;
  background: $color_theme;
  color: #FFF;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  position: relative;

  i {
    right: 20px;
    position: absolute;
    transition: all 0.3s ease-in-out;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0; }

  &:hover {
    background: #333;
    color: #FFF;
    padding: 14px 45px 14px 37px;

    i {
      right: 15px;
      opacity: 1; } } }


.section-details {
  padding-bottom: 10px;
  border-bottom: 1px solid #dfe4e7;
  margin-bottom: 50px; }

.section-title {
  position: relative;
  border-right: 1px solid #dfe4e7;
  margin-right: 20px;
  margin-bottom: 30px;

  h2 {
    margin: 0;
    color: #0e0e0e;
    font-size: 40px;
    font-weight: 700;
    z-index: 2;
    position: relative; }

  h5 {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 300;
    z-index: 2;
    position: relative; }

  span {
    font-size: 115px;
    font-weight: 300;
    line-height: 52px;
    z-index: 1;
    position: absolute;
    top: 0;
    left: -10px;
    color: lighten($color_theme, 35%); } }

.section-title-two {
  position: relative;
  margin-bottom: 30px;

  h2 {
    margin: 0;
    color: #0e0e0e;
    font-size: 40px;
    font-weight: 700;
    z-index: 2;
    position: relative; }

  h5 {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 300;
    z-index: 2;
    position: relative; }


  span {
    font-size: 115px;
    font-weight: 300;
    line-height: 52px;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    color: #eeeeee; } }

.section-caption {
  p {
    color: #5f5f5f;
    font-weight: 300;
    line-height: 26px; } }

.title-color-white {
  h2 {
    color: #fff; }

  h5 {
    color: #FFF; }

  span {
    color: #545454; } }

.color-white {
  color: #FFF; }

.border-none {
  border: none; }

.no-margin {
  margin: 0; }

.bgt {
  background: transparent;
  border: 1px solid #0e0e0e;
  color: #0e0e0e;
  padding: 13px 37px !important;
  margin-left: 15px; }




/* Layout for search container */
.search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba($color_theme, 0.95); }

.search {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; }

.search::before,
.search::after {
  content: '';
  position: absolute;
  width: calc(100% + 15px);
  height: calc(100% + 15px);
  pointer-events: none;
  border: 1.5em solid darken($color_theme, 10%); }

.search::before {
  top: 0;
  left: 0;
  border-right-width: 0;
  border-bottom-width: 0; }

.search::after {
  right: 0;
  bottom: 0;
  border-top-width: 0;
  border-left-width: 0; }


.btn--search-close {
  font-size: 2em;
  position: absolute;
  top: 1.25em;
  right: 1.25em;
  display: none; }

.btn--search-close {
  display: block; }

.search__form {
  margin: 5em 0; }

.search__input {
  font-family: inherit;
  font-size: 10vw;
  line-height: 1;
  display: inline-block;
  box-sizing: border-box;
  width: 75%;
  padding: 0.05em 0;
  color: #FFF;
  border-bottom: 2px solid; }


.search__input::-webkit-input-placeholder {
  opacity: 0.3;
  color: #060919; }


.search__input::-moz-placeholder {
  opacity: 0.3;
  color: #f5f5f5; }


.search__input:-ms-input-placeholder {
  opacity: 0.3;
  color: #060919; }


.search__input::-webkit-search-cancel-button,
.search__input::-webkit-search-decoration {
  -webkit-appearance: none; }


.search__input::-ms-clear {
  display: none; }


.search__info {
  font-size: 90%;
  font-weight: bold;
  display: block;
  width: 75%;
  margin: 0 auto;
  padding: 0.85em 0;
  text-align: right;
  color: #fff; }


.search__related {
  display: flex;
  width: 75%;
  pointer-events: none; }


.search__suggestion {
  width: 33.33%;
  text-align: left; }


.search__suggestion:nth-child(2) {
  margin: 0 3em; }


.search__suggestion h3 {
  font-size: 1.35em;
  margin: 0; }


.search__suggestion h3::before {
  content: '\21FE';
  display: inline-block;
  padding: 0 0.5em 0 0; }


.search__suggestion p {
  font-size: 1.15em;
  line-height: 1.4;
  margin: 0.75em 0 0 0; }


/************************/
/* Transitions      */
/************************/

.search {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.5s; }


.search--open {
  pointer-events: auto;
  opacity: 1;
  z-index: 99999999; }


/* Border */
.search::before,
.search::after {
  transition: transform 0.5s; }


.search::before {
  transform: translate3d(-15px, -15px, 0); }


.search::after {
  transform: translate3d(15px, 15px, 0); }


.search--open::before,
.search--open::after {
  transform: translate3d(0, 0, 0); }


/* Close button */
.btn--search-close {
  opacity: 0;
  transform: scale3d(0.8, 0.8, 1);
  transition: opacity 0.5s, transform 0.5s;
  background: transparent;
  border: none;
  &:focus {
    outline: none; }

  i {
    color: #FFF; } }


.search--open .btn--search-close {
  opacity: 1;
  transform: scale3d(1, 1, 1); }


.search__input {
  font-family: inherit;
  font-size: 5vw;
  line-height: 1;
  display: inline-block;
  box-sizing: border-box;
  width: 75%;
  padding: 0.05em 0;
  color: #FFF;
  border: none !important;
  border-bottom: 2px solid #FFF !important;
  outline: none; }


.search__input {
  border: 0;
  background: transparent;
  border-radius: 0;
  -webkit-appearance: none; }


/* Search form with input and description */
.search__form {
  opacity: 0;
  transform: scale3d(0.8, 0.8, 1);
  transition: opacity 0.5s, transform 0.5s; }


.search--open .search__form {
  opacity: 1;
  transform: scale3d(1, 1, 1); }


.search__suggestion {
  opacity: 0;
  transform: translate3d(0, -30px, 0);
  transition: opacity 0.5s, transform 0.5s; }


.search--open .search__suggestion {
  opacity: 1;
  transform: translate3d(0, 0, 0); }


.search--open .search__suggestion:nth-child(2) {
  transition-delay: 0.1s; }


.search--open .search__suggestion:nth-child(3) {
  transition-delay: 0.2s; }


@media screen and (max-width:40em) {
  .search__form {
    margin: 5em 0 1em; }

  .btn--search-close {
    font-size: 1.25em;
    top: 1.5em;
    right: 1.5em; }

  .search__info {
    text-align: left; }

  .search__suggestion {
    font-size: 80%;
    width: 100%; }

  .search__suggestion:nth-child(2),
  .search__suggestion:nth-child(3) {
    display: none; } }



@media screen and (max-width: 1024px) {
  .dt-btn {
    padding: 12px 30px; }

  .section-title {
    h2 {
      font-size: 30px; }

    span {
      font-size: 85px; } } }

@media screen and (max-width: 768px) {
  .section-title-two {
    h2 {
      font-size: 30px; }

    span {
      font-size: 90px; } }
  .dt-btn {
    padding: 10px 23px;
    font-size: 11px; } }

@media screen and (max-width: 500px) {

  .section-title {
    border-right: none;
    h2 {
      font-size: 24px; }

    span {
      font-size: 70px; } } }



/* Page Loader */
#loader {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 1);
  top: 0;
  left: 0;
  position: fixed;
  z-index: 999999;

  &.loaded {
    display: none; } }


.loader {
  position: absolute;

  left: 50%;
  top: 50%; }


.loader--glisteningWindow {
  width: 0.25em;
  height: 0.25em;
  box-shadow: 0.70711em 0.70711em 0 0em #2ecc71, -0.70711em 0.70711em 0 0.17678em #9b59b6, -0.70711em -0.70711em 0 0.25em #3498db, 0.70711em -0.70711em 0 0.17678em #f1c40f;
  animation: gw 1s ease-in-out infinite, rot 2.8s linear infinite; }

@keyframes rot {
  to {
    transform: rotate(360deg); } }


@keyframes gw {
  0% {
    box-shadow: 0.70711em 0.70711em 0 0.125em #2ecc71, -0.70711em 0.70711em 0 0.39017em #9b59b6, -0.70711em -0.70711em 0 0.5em #3498db, 0.70711em -0.70711em 0 0.39017em #f1c40f; }

  25% {
    box-shadow: 0.70711em 0.70711em 0 0.39017em #2ecc71, -0.70711em 0.70711em 0 0.5em #9b59b6, -0.70711em -0.70711em 0 0.39017em #3498db, 0.70711em -0.70711em 0 0.125em #f1c40f; }

  50% {
    box-shadow: 0.70711em 0.70711em 0 0.5em #2ecc71, -0.70711em 0.70711em 0 0.39017em #9b59b6, -0.70711em -0.70711em 0 0.125em #3498db, 0.70711em -0.70711em 0 0.39017em #f1c40f; }

  75% {
    box-shadow: 0.70711em 0.70711em 0 0.39017em #2ecc71, -0.70711em 0.70711em 0 0.125em #9b59b6, -0.70711em -0.70711em 0 0.39017em #3498db, 0.70711em -0.70711em 0 0.5em #f1c40f; }

  100% {
    box-shadow: 0.70711em 0.70711em 0 0.125em #2ecc71, -0.70711em 0.70711em 0 0.39017em #9b59b6, -0.70711em -0.70711em 0 0.5em #3498db, 0.70711em -0.70711em 0 0.39017em #f1c40f; } }






