#brand {
  padding: 70px 0;
  background: #f5f5f7; }

.brand-wrapper {
  display: flex;
  align-items: center;
  .brand-item {
    float: left;
    width: 16.66%;
    text-align: center;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      font-size: 40px;
      background: #dfe4e7;
      right: 0;
      height: 45px;
      top: -3px;
      width: 3px;
      transform: rotate(25deg); }

    &:last-child {
      &:after {
        display: none; } } } }

@media screen and (max-width: 768px) {

  .brand-wrapper {
    .brand-item {
      width: 50%;
      margin-bottom: 40px;

      &:after {
        display: none; } } } }
