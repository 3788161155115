#website {
  padding-top: 100px;
  background: #f5f5f7; }

.website-slider {
  background: #FFF;
  padding: 10px 0; }

.web-slidera-control {
  display: flex;

  .control {
    width: 33%;
    float: left; } }


.web-slidera-control {
  padding: 20px 0; }

.control {
  .banner-next, .banner-prev {}

  .banner-next {
    float: right; } }
.website {
  overflow: hidden;
  cursor: pointer;

  img {
    transform: scale(1);
    transition: all 0.3s ease-in-out; }

  .slider-content {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.8);
    text-align: center;
    opacity: 0;
    transition: all 0.3s ease-in-out;


    .content {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      transform: translate(-50%,-50%);

      h3 {
        color: #FFF;
        margin-bottom: 15px;
        font-size: 30px;
        font-weight: 700; }

      .dt-btn-two {
        padding: 13px 30px; } } }

  &:hover {
    img {
      transform: scale(1.1); }

    .slider-content {
      opacity: 1; } } }


.web-slidera-control {
  .banner-next, .banner-prev {
    position: relative;
    cursor: pointer;
    display: inline-block;

    &:before, &:after {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      transition: all 0.3s ease-in-out; }

    &:hover {
      .next-btn, .prev-btn {
        opacity: 1; }

      &:after {
        width: 100px; } } }

  .banner-next {
    .next-btn {
      margin-right: 10px;
      margin-top: 25px; }


    &:before {
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 20px solid $color_theme;
      right: -17px;
      top: 14px; }

    &:after {
      position: absolute;
      content: '';
      width: 50px;
      height: 2px;
      background: $color_theme;
      right: -13px;
      top: 23px; } }

  .banner-prev {
    .prev-btn {
      margin-top: 23px;
      margin-left: 27px; }

    &:before {
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 20px solid $color_theme;
      left: -5px;
      top: 14px; }

    &:after {
      position: absolute;
      content: '';
      width: 50px;
      height: 2px;
      background: $color_theme;
      left: 0;
      top: 23px; } }

  .next-btn, .prev-btn {
    transition: all 0.3s ease-in-out;
    letter-spacing: 2px;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 13px;
    opacity: 0; } }

@media screen and (max-width: 768px) {
  .website-slider {
    .swiper-container {
      width: 60%; } } }


@media screen and (max-width: 500px) {
  .website-slider {
    .swiper-container {
      width: 100%; } }

  .web-slidera-control {
    .control {
      width: 20%;

      &:nth-child(2) {
        width: 60%; } } } }

