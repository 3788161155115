#faq-categories {
  padding: 100px 0; }


.faq-cate {


  h3 {
    background: #eeeeee;
    padding: 17px;
    font-size: 15px;
    color: #0e0e0e;
    border-bottom: 1px solid #cececf;
    margin: 0;
    text-align: center; } }

.faq-tab-nav {
  background: #f5f5f7;
  margin: 0;
  padding: 30px 0;
  list-style: none;

  li {
    line-height: 30px;

    a {
      text-decoration: none;
      color: #303030;
      line-height: 40px;
      padding: 0 40px;
      display: block;


      &:hover {
        background: $color_theme;
        color: #FFF; } } } }
