#pricing, #pricing-three {
  padding: 100px 0;
  background: lighten($color_theme, 57%);
  border-top: 1px solid lighten($color_theme, 30%);
  border-bottom: 1px solid lighten($color_theme, 30%); }

.pricing__item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  flex: 0 1 330px;
  max-width: 400px;
  margin: 0 auto 20px; }

.pricing__feature-list {
  text-align: left; }

.pricing__action {
  color: inherit;
  border: none;
  background: none; }


.pricing__action:focus {
  outline: none; }

.pricing--yama .pricing__item {
  padding: 0 0 3.5em;
  color: #fff;
  background: #fbfcff;
  transition: background 0.3s;
  cursor: pointer;
  border: 1px solid lighten($color_theme, 35%);
  transition: all 0.25s ease-in-out;

  &:hover {
    background: #f5f5f7;
    box-shadow: 0 25px 30px -10px rgba(0,0,0,0.15); } }

.pricing--yama .pricing__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  width: 290px;
  margin: 0 auto 20px;
  padding-top: 45px;
  color: #14171a; }

.pricing__amp {
  padding: 0.15em 0 0.1em;
  color: #0f0e0f; }


.pricing--yama .pricing__sentence {
  margin-bottom: 30px;
  color: #5f5f5f; }

.pricing--yama .pricing__price {
  font-size: 50px;
  line-height: 55px;
  font-weight: 300;
  position: relative;
  z-index: 10;
  overflow: hidden;
  padding: 10px;
  cursor: default;
  color: #000;
  background: lighten($color_theme, 35%);
  -webkit-transition: color 0.3s;
  transition: color 0.3s;

  sup {
    top: -24px;
    font-size: 25px; } }


.pricing--yama .pricing__item:hover .pricing__price {
  color: #fff; }

.pricing--yama .pricing__price::before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: $color_theme;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transform: translate3d(-150%,0,0) skewX(40deg);
  transform: translate3d(-150%,0,0) skewX(40deg); }

.pricing--yama .pricing__item:hover .pricing__price::before {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0) skewX(0deg);
  transform: translate3d(0,0,0) skewX(0deg); }

.pricing--yama .pricing__period {
  font-size: 0.5em;
  font-weight: normal;
  display: block;
  color: #848484; }

.pricing--yama .pricing__feature-list {
  margin: 0;
  padding: 30px 50px;
  list-style: none;
  // text-align: center
  color: #6a6563;

  li {
    font-size: 14px;
    font-weight: 300;
    color: #000;
    line-height: 30px;

    i {
      color: #000;
      margin-right: 10px; } } }

.pricing--yama .pricing__action {
  font-weight: 700;
  letter-spacing: 2.5px;
  margin: 0 3.5em;
  padding: 1em 2em;
  background: lighten($color_theme, 35%);
  color: #14171a;
  text-transform: uppercase;
  -webkit-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s; }


.pricing--yama .pricing__action:hover,
.pricing--yama .pricing__action:focus {
  color: #fff;
  background: $color_theme; }


/*=========  Pricing Two  ==========*/

#pricing_two {
  padding: 100px 0;
  background-size: cover; }

.price-control {
  padding: 60px;
  margin-top: 50px;
  background-color: rgba(245, 245, 247, 0.102);
  position: relative;

  .border1, .border2, .border3, .border4 {
    position: absolute;
    height: 20px;
    width: 2px;
    background-color: rgba(223, 228, 231, 0.4);
    bottom: 25px; }

  .border1 {
    left: 6.6%; }

  .border2 {
    left: 35.7%; }

  .border3 {
    right: 35.3%; }

  .border4 {
    right: 6.4%; } }

.rangeslider__fill {
  background: $color_theme; }

.rangeslider {
  background: #8f9091; }

.rangeslider,
.rangeslider__fill {
  border-radius: 0; }

.rangeslider__handle {
  border-radius: 0;
  height: 30px;
  width: 30px;
  background: $color_theme;
  top: -5px;
  border: none;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  position: relative;

  &:after {
    height: 100%;
    width: 1px;
    background: darken($color_theme, 10%);
    border-radius: 0;
    right: -9px; }

  &:before {
    height: 100%;
    width: 1px;
    background: darken($color_theme, 10%);
    border-radius: 0;
    content: '';
    position: absolute;
    left: 12px; } }


.rangeslider--horizontal {
  .rangeslider__handle {
    top: -5px; } }

.price-plan {
  margin-top: 30px;

  .config {
    margin: 0;
    padding: 0;

    li {
      display: block;
      padding: 20px 0;
      text-align: center;

      &.config-head {
        background: $color_theme;
        color: #fff;
        font-size: 15px;
        font-weight: 700;
        border-bottom: 2px solid #000; }

      &.config-space {
        background: #fff;
        font-weight: 700; } } }

  > div {
    display: none;

    &.active {
      display: block; } } }

.item-price {
  margin-top: 50px;
  h3 {
    display: inline-block;
    color: #FFF;
    font-weight: 700;
    margin-right: 20px;
    font-size: 30px;
    margin-top: 0;
    border-bottom: 3px solid #FFF;

    span {
      font-size: 20px; } }

  .dt-btn {
    background: rgba(255,255,255,0.1);
    border: 1px solid rgba(255,255,255,0.1);

    &:hover {
      background: $color_theme; } } }

/*=========  Pricing Two  ==========*/

.pricing_item {
  background: #FFF;
  border-bottom: 3px solid $color_theme;
  padding-bottom: 30px;
  position: relative;
  cursor: pointer;

  .pricing-head {
    text-align: center;
    padding: 30px 0;
    background: $color_theme;


    h3 {
      color: #FFF;
      font-size: 28px;
      font-weight: 700;
      margin-top: 0; }

    p {
      color: #FFF;
      font-weight: 300;
      margin-bottom: 0; } }

  .pricing_price {
    margin-top: 50px;
    margin-left: 40px;
    h2 {
      color: $color_theme;
      font-weight: 700;
      font-size: 60px;
      margin-bottom: 0;

      span {
        font-size: 14px; } } }

  .pricing__feature-list {
    padding: 30px 65px;

    li {
      position: relative;

      &:before {
        position: absolute;
        top: 14px;
        left: -25px;
        width: 15px;
        height: 1px;
        background: $color_theme;
        content: ''; } } }

  .dt-btn {
    float: right;
    margin-right: 20px;
    padding: 10px 28px; } }


.price-active {
  .pricing-head {
    background: #000; }
  h6 {
    position: absolute;
    color: #FFF;
    text-transform: uppercase;
    font-size: 12px;
    background: $color_theme;
    padding: 12px 10px 12px 30px;
    transform: rotate(-90deg);
    right: 0px;
    top: 19px;

    &:before {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-left: 19px solid #000;
      left: -1px;
      bottom: -2px; } }

  .dt-btn {
    background: #000;

    &:hover {
      background: $color_theme; } } }

@media screen and (max-width: 1024px) {

  .price-control {
    .border1 {
      left: 8%; }

    .border2 {
      left: 36%; }

    .border3 {
      right: 35.6%; }

    .border4 {
      right: 7.8%; } } }


@media screen and (max-width: 992px) {

  .price-control {
    padding: 40px 60px;
    .border1 {
      left: 10.5%;
      bottom: 10px; }

    .border2 {
      left: 37%;
      bottom: 10px; }

    .border3 {
      right: 36.5%;
      bottom: 10px; }

    .border4 {
      right: 10%;
      bottom: 10px; } }

  .price-plan {
    .config li {
      padding: 10px 0; } } }

@media screen and (max-width: 768px) {
  #pricing {
    padding: 50px 0; }

  .pricing_item {
    max-width: 450px;
    margin: 0 auto 30px; } }
