#choose {
  padding-top: 100px;

  &.choose-two {
    padding: 100px 0;
    background: #FFF; } }

.choose-item {
  position: relative;
  margin-bottom: 100px;


  h4 {
    font-weight: 700;
    font-size: 18px;
    position: relative;
    z-index: 2; }

  p {
    color: #5f5f5f;
    font-weight: 300;
    position: relative;
    z-index: 2; }

  span {
    font-size: 50px;
    color: lighten($color_theme, 35%);
    position: absolute;
    top: -4px;
    left: -18px;
    z-index: 1; } }

.choose-two {
  .choose-item {
    &.right-item {
      span {
        right: -18px; } } } }

.choose-two-thumb {
  max-width: 400px;
  margin: 0 auto;
  text-align: center; }


@media screen and (max-width: 1024px) {

  #choose {
    img {
      max-width: 365px;
      margin: 0 auto;
      display: block; } } }


@media screen and (max-width: 768px) {

  #choose {
    img {
      max-width: 400px;
      display: block;
      margin: 20px auto 0; }

    &.choose-two {
      padding: 50px 0; } }

  .choose-item {
    max-width: 400px;
    margin: 0 auto 40px;

    &.no-margin {
      margin: 20px auto 40PX; } } }

@media screen and (max-width: 500px) {
  #choose {
    padding: 50px 10px;

    img {
      max-width: 250px; } } }
