#domain-price {
  padding: 100px 0; }

.domain-pricing {
  margin-top: 50px;
  .table {
    border-collapse: separate;
    border-spacing: 0 8px;


    > thead {
      > tr {
        > th {
          padding: 25px 0;
          background: $color_theme;
          color: #FFF;
          font-size: 14px;
          text-transform: uppercase;
          border: none;
          text-align: center;
          border-right: 1px solid darken($color_theme, 10%);
          margin-bottom: 5px;

          &:first-child {
            background: transparent; } } }


      // > td
 }      //   width: 94px

    tbody {
      tr {
        transform: scale(1);
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        td {
          background: #f5f5f7;
          height: 70px;
          border-top: none;
          text-align: center;

          &:first-child {
            width: 200px; }

          p {
            margin-bottom: 0;
            color: #0e0e0e;
            font-size: 14px;

            span {
              margin-right: 20px;
              color: $color_theme;
              text-decoration: line-through; } } }

        .dt-btn {
          padding: 13px 22px;
          margin: 0; } } } } }

@media screen and (max-width: 768px) {
  // (min-device-width: 768px) and (max-device-width: 1024px)

  /* Force table to not be like tables anymore */
  table, thead, tbody, th, td, tr {
    display: block; }

  tr {
    &:nth-of-type(odd) {
      background: #f5f5f5; } }



  .domain-pricing {
    .table {
      thead {
        tr {
          position: absolute;
          top: -9999px;
          left: -9999px; } }

      tbody {
        tr {

          td {
            padding: 10px;
            background-color: transparent;
            text-align: left;

            &:first-child {
              width: 100%; }

            p {
              line-height: 47px; }

            .dt-btn {
              margin: 5px 0;
              padding: 12px 27px;
              float: none; } } } }





      tr {
        border: 1px solid #ccc; }

      td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50% !important; }


      td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 14px; }


      td:nth-of-type(1) {
        &:before {
          content: "Registation"; } }

      td:nth-of-type(2) {
        &:before {
          content: "Domain Search"; } }

      td:nth-of-type(3) {
        &:before {
          content: "Dns Management"; } }

      td:nth-of-type(4) {
        &:before {
          content: "Whois Privacy"; } } } } }


@media screen and (max-width: 500px) {
  td {
    padding-left: 38% !important;
    &:before {
      width: 30%; } }

  tbody {
    tr {
      td {
        h4 {
          margin: 11px 10px 0 0;
          font-size: 13px; }

        h3 {
          font-size: 16px; }

        p, span, h5 {
          font-size: 13px; } } } } }

