#install {
  padding: 80px 0;
  position: relative;

  .install-content {
    position: relative;
    padding-left: 250px;

    img {
      position: absolute;
      left: 0;
      top: -180px; }

    h4 {
      color: #FFF;
      font-size: 15px;
      font-weight: 300;
      margin: 0;
      display: table;
      margin-bottom: 15px; }

    h2 {
      color: #FFF;
      margin: 0;
      font-size: 36px;
      display: inline-block; }

    .dt-btn {
      float: right;
      display: inline-block; } } }

@media screen and (max-width: 1024px) {
  #install {
    .install-content {
      padding-left: 170px;

      .dt-btn {
        float: none;
        margin-top: 20px;
        display: table; }

      h2 {
        font-size: 24px; } } } }



@media screen and (max-width: 500px) {

  #install {
    .install-content {
      padding-left: 0;

      img {
        display: none; } } } }

