#create-website {
  padding: 100px 0;
  background-size: cover; }


.site-configeration {
  h5 {
    color: #c2c2c2;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 16px;
    text-decoration: underline; }

  h3 {
    font-size: 30px;
    font-weight: 700;
    color: #ffffff;
    margin-top: 0;
    margin-bottom: 0px; }

  .configer {
    margin: 0;
    padding: 30px 0;
    list-style: none;

    li {
      font-size: 13px;
      font-weight: 300;
      color: #c2c2c2;
      padding: 7px 0;

      span {
        font-weight: 700;
        margin-right: 10px; } } } }

.config-thumb {
  float: right;

  img {
    width: 100%; } }

.site-price {
  position: relative;
  .low-price {
    position: relative;
    text-align: center;


    &:after {
      position: absolute;
      content: '';
      height: 125px;
      width: 125px;
      background-color: rgba($color_theme, 0.6);
      transform: rotate(45deg);
      left: 50px;
      top: 50px;
      border: 1px solid $color_theme; }

    p {
      color: #FFF;
      position: absolute;
      z-index: 1000;
      top: 100px;
      font-size: 13px;
      left: 59px; } } }

.offer-price {
  position: absolute;
  text-align: center;

  &:before {
    position: absolute;
    content: '';
    background-color: rgba($color_theme, 0.6);
    border: 1px solid $color_theme;
    height: 90px;
    width: 90px;
    transform: rotate(45deg);
    top: 183px;
    left: 28px; }

  p {
    color: #FFF;
    top: 215px;
    position: relative;
    left: 39px;
    font-size: 13px; } }

@media screen and (max-width: 768px) {

  .site-configeration {
    margin-bottom: 40px; } }


@media screen and (max-width: 500px) {

  #create-website {
    padding-top: 50px; }

  .site-configeration {
    .configer {
      li {
        padding: 0; } } }

  .config-thumb {
    height: 150px;
    width: 190px; }
  .site-price {
    .low-price {
      &:after {
        height: 90px;
        width: 90px;
        left: 37px;
        top: 0; }

      p {
        top: 28px;
        left: 38px;
        font-size: 11px; } } }


  .offer-price {
    &:before {
      height: 70px;
      width: 70px;
      top: 107px; }

    p {
      top: 119px;
      left: 35px;
      font-size: 11px; } } }
