#offer {
  padding: 100px 0; }

.tab {

  position: relative;
  font-weight: 300;
  color: #888;
  -webkit-font-smoothing: antialiased;
  text-align: left; }

.tabs {
  display: table;
  position: relative;
  margin: 0;
  padding: 0;
  background: #0e0e0e;
  overflow: visible;
  list-style: none;

  li {
    line-height: 38px;
    padding: 0;
    position: relative;
    border-right: 1px solid #343434;
    text-align: center;
    display: inline-block;

    &:last-child {
      border-right: none; }

    a {

      color: #fff;
      display: block;
      letter-spacing: 0;
      outline: none;
      padding: 12px 24px;
      text-decoration: none;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      text-transform: uppercase;
      font-weight: 700; }

    &.current a {
      color: #fff;
      background: $color_theme;
      position: relative;

      &:after {
        position: absolute;
        content: '';
        left: 50%;
        bottom: -10px;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 13px solid transparent;
        border-right: 13px solid transparent;
        border-top: 10px solid $color_theme; } } } }

.tabs-two {
  li {
    display: block;
    border-bottom: 1px solid #fff;

    a {
      font-size: 11px;
      &:after {
        display: none; } } } }


.tab_content {
    margin-top: 80px; }

.tabs_item {
    display: none;
    margin-bottom: 70px;

    img {
      width: 100%;
      max-height: 380px; }

    .content {

      h4 {
        color: #0e0e0e;
        font-size: 20px;
        margin-top: 0; }

      .dt-btn {
        margin-top: 40px; } }

    &:first-child {
        display: block; }

    p {

        font-size: 14px;
        color: #5f5f5f;
        line-height: 26px;
        margin-bottom: 20px; }

    &:first-child {
        display: block; } }

@media screen and (max-width: 1024px) {
  .tabs {
    margin: 0 auto;
    display: block;


    li {
      line-height: 28px;
      a {
        font-size: 10px;
        padding: 12px 7px;
        font-weight: 400; } } }

  .tabs_item {
    .content {
      .dt-btn {
        margin-top: 10px; } }

    img {
      max-width: 400px;
      margin: 0 auto;
      display: block; } }


  .tabs li {} }

@media screen and (max-width: 768px) {

  #offer {
    padding: 50px 0; }

  .tabs {
    margin: 0 auto;
    display: block;

    li {
      width: 32.7%;
      a {
        font-size: 10px;
        padding: 5px 0;
        font-weight: 400;

        border-bottom: 1px solid #333;

        &:after {
          display: none; } } }

    &.tabs-two {
      width: 90%;
      margin: 0 auto;
      li {
        width: 100%; } } }


  .tabs_item {
    img {
      max-width: 350px;
      margin: 0 auto;
      display: block;

      margin-bottom: 20px; } } }

@media screen and (max-width: 500px) {

  .tabs {
    li {
      width: 48.7%; } } }
