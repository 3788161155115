#header {
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;

  .logo {
    display: block; }

  .logo-fixed {
    display: none; }

  &.navbar-small {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 99999;
    box-shadow: 0 1px 20px rgba(0,0,0,0.1);
    .top-menu {
      display: none; }

    .logo {
      display: none; }

    .logo-fixed {
      display: block; }

    .navbar {
      background: #FFF;
      padding: 10px 0;
      animation-timing-function: ease-in-out;
      animation-duration: 0.50s;
      animation-name: menu_sticky;
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      box-shadow: 0 1px 20px rgba(0,0,0,0.1);
      z-index: 9999;

      .slimmenu {
        background: transparent;

        li {
          a {
            color: #000; }

          &.header-search {
            color: #000;

            &:before {
              background: #000; } }

          .child-menu {
            top: 115%; } } } } }



  &.header-two {
    .logo-fixed {
      display: none; }

    &.navbar-small {
      position: fixed;

      .logo-fixed {
        display: inline-block; }

      .navbar {
        background: #FFF;
        padding: 10px 0; }

      .slimmenu {
        width: auto;
        float: right;

        li {
          &.top-contact {
            display: none; } } } } }


  &.header-three {
    .logo-fixed {
      display: none; } } }





ul {
  &.slimmenu {
    li {
      background-color: transparent;
      float: none;

      .sub-toggle {
        background-color: transparent; } }

    > li {
      border-left: none; } } }



.top-menu {
  background-color: #070707;
  padding: 10px 0;
  border-bottom: 1px solid $color_theme;

  .top-contact {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      padding: 0 20px;
      color: #FFF;
      border-right: 1px solid #545c87;
      font-size: 13px;

      &:last-child {
        border-right: none; }

      i {
        margin-right: 10px; }

      a {
        color: #FFF;

        &:hover {
          color: $color_theme; } } } }

  .dt-social-link {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block;


    li {
      display: inline-block;
      margin: 0 8px;

      a {
        color: #FFF;

        &:hover {
          color: $color_theme; } } } }

  .login {
    float: right;

    a {
      padding: 10px 20px;
      color: #FFF;

      &:hover {
        background: $color_theme; } } } }


.slimmenu {
  background: #171717;
  margin-bottom: 0;
  border: none;
  padding: 25px 0; }


.navbar-brand {
  padding: 0; }


.navbar {
  background: #171717;
  margin-bottom: 0;
  border: none;
  padding: 25px 0;
  border-radius: 0;
  .slimmenu {
    li {
      padding: 15px 15px;
      a {
        color: #FFF;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
        position: relative;
        padding: 0;

        &:after {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0;
          content: '';
          width: 0;
          height: 1px;
          background: $color_theme;
          transition: all 0.3s ease-in-out; }

        &:hover, &:focus {
          background-color: transparent;
          color: $color_theme;

          &:after {
            width: 100%; } } }

      &.header-search {
        color: #FFF;
        position: relative;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        &:before {
          content: '';
          height: 14px;
          width: 1px;
          left: 0;
          top: 18px;
          position: absolute;
          background: #FFF; } }

      .child-menu {
        width: 200px;
        z-index: 999;
        margin: 0px;
        padding: 0px;
        border-top: 2px solid $color_theme;
        top: 76px;

        li {
          width: 100%;
          padding: 0;
          border-bottom: 1px solid #4e4e4e;
          transition: all 0.3s ease-in-out;
          background: #000;

          a {
            display: block;
            padding: 0;
            color: #FFF !important;
            transition: all 0.3s ease-in-out;

            font-weight: 300;
            font-size: 12px;
            padding: 6px 10px;

            &:after {
              display: none; } }

          &:last-child {
            border-bottom: none; }

          &:hover {
            background-color: $color_theme;
            a {
              color: #FFF !important;
              display: block;
              text-decoration: none !important;
              padding-left: 15px; } } } } } } }

.sub-toggle {
    display: none; }
/*=========  Header Two  ==========*/
#header {
  &.header-two {
    .top-menu {
      background: transparent; }

    .login {
      margin: 15px 0;
      a {
        background: rgba(255,255,255,0.1);
        border: 1px solid #040813;
        font-size: 13px;
        padding: 11px 30px; } }

    .navbar {
      padding: 0;
      background-color: transparent;
      position: relative;

      .top-contact {
        position: absolute;
        right: 0;

        a {
          display: inline-block;
          margin-left: 20px;
          color: #FFF;
          position: relative;
          font-weight: 300;


          i {
            margin-right: 10px;




            &:hover {
              color: $color_theme; } } } } }

    .slimmenu {
      background-color: transparent;
      position: relative;

      li {
        &:first-child {
          padding-left: 0; } } } } }

/*=========  Header Three  ==========*/

#header {
  &.header-three {
    .top-menu {
      padding: 24px 0;
      .top-info {
        margin: 4px 0;

        .info-icon {
          float: left;
          height: 40px;
          width: 40px;
          background: $color_theme;
          line-height: 40px;
          border-radius: 50%;
          text-align: center;

          i {
            color: #FFF; } }

        .info-details {
          margin-left: 50px;
          h6 {
            color: #FFF;
            margin: 0;
            font-size: 14px;
            line-height: 23px; }
          p {
            margin-bottom: 0;

            font-size: 13px;
            font-weight: 300;
            color: #b3b3b3; } } } }

    .live-chat {
      text-align: center;
      margin: 5px 0;

      i {
        height: 40px;
        width: 40px;
        line-height: 40px;
        background: $color_theme;
        color: #FFF;
        border-radius: 50%;
        font-size: 20px; }

      a {
        margin-left: 10px;
        color: #FFF; } }

    .navbar {
      background: #FFF;
      padding: 0;

      .slimmenu {
        background-color: transparent;
        position: relative;

        li {
          padding: 0;
          a {
            color: #0e0e0e;
            display: block;
            padding: 15px;


            &:hover {
              background: $color_theme;
              color: #FFF;

              &:after {
                display: none; } } }

          .child-menu {
            li {
              a {
                padding: 7px 10px; } } }

          &.login {

            position: absolute;
            right: 0;
            top: 7px;
            a {
              padding: 8px 20px;
              background: $color_theme;
              border-radius: 20px;
              color: #FFF;
              text-decoration: none;
              display: inline-block;

              &:hover {} } } } } }

    &.navbar-small {
      position: fixed;

      .logo-fixed {
        display: inline-block; }

      .navbar {
        background: #FFF;
        padding: 10px 0; }

      .slimmenu {
        width: auto;
        float: right;

        li {
          a {
            &:hover {
              background: transparent !important;
              color: $color_theme; } }

          &.login {
            display: none; } } } } } }


@keyframes menu_sticky {
  0% {
    margin-top: -40px;
    opacity: 0; }
  50% {
    margin-top: -20px;
    opacity: 0; }
  100% {
    margin-top: 0;
    opacity: 1; } }

/* Header Fixed */
.home {
  #header {
    position: absolute; } }




/* Mobile Menu */

#mobile-header {
  text-align: center;
  background: #222533;
  padding: 8px 10px;
  height: 50px;
  width: 100%;
  display: none;
  position: fixed;
  z-index: 10000;

  .menu-toggle {
    position: absolute;
    left: 0; }

  .main-logo {
    width: 100px;
    display: inline-block;
    img {
      width: 100%; } } }


/* Back To Top */
.return-to-top {
  position: fixed;
  bottom: 50px;
  right: 50px;
  background: $color_theme;
  width: 40px;
  height: 40px;
  z-index: 1000;
  text-decoration: none;
  opacity: 0;
  transition: all 0.3s ease;

  &.visible {
    opacity: 1; }

  i {
    color: #fff;
    margin: 0;
    position: relative;
    left: 11px;
    top: 9px;
    font-size: 19px;
    transition: all 0.3s ease; }

  &:hover {
    background: rgba(0, 0, 0, 0.9);

    i {
      color: #fff;
      top: 5px; } } }

/*=========  Push Menu  ==========*/
#site {
  position: relative;
  top: 0;
  left: 0;

  box-sizing: border-box;
  transition: all 0.3s; }

.mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.8); }

.toggle-menu {
  width: 48px;
  height: 48px;
  background-size: 48px 48px;
  border: none;
  position: absolute;
  top: 6px;
  left: 6px; }

#mobile-wrapper {
  width: 300px;
  height: 100vh;
  overflow: hidden;
  transition: all 0.3s;
  position: fixed;
  top: 0;
  left: -300px;
  z-index: 20;
  background: #000;
  // height: 100vh

  .mobile-menu-container {

    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none; } } }

  #mobile_menu_search {
    padding: 12px 0;
    text-align: center;
    font: inherit;
    background: #2b2b2b;
    margin-bottom: 20px;
    #search {
      form {
        input, #searchsubmit {
          border: 1px solid rgba(145,157,201,.14);
          background: #19172f;
          padding: 10px;
          outline: none;
          border-right: none;
          color: #FFF;

          #searchsubmit {
            border-left: none;
            border-right: 1px solid rgba(145,157,201,.14);
            margin-left: -5px; } } } } } }


body.menu-open {
  overflow: hidden;
  #mobile-wrapper {
    left: 0; }

  #site {
    left: 300px;
    overflow-x: hidden; } }


.no-scroll {
  overflow-x: hidden; }


#wrapper {
  padding: 15px 60px; }

h1,h2,h3,h4,h5,h6 {
  text-transform: capitalize; }


/*=========  Acordian Mobile Menu  ==========*/

.accordion-menu {
  width: 100%;
  max-width: 350px;
  margin: 60px auto 20px;
  // background: #fff
  border-radius: 4px;

  > li {
    border-bottom: 1px solid #333;
    > a {

      font-weight: 500;
      font-size: 14px;
      text-decoration: none;
      text-transform: uppercase;
      padding: 10px; } } }



.accordion-menu li.open .dropdownlink {
  color: $color_theme;
  .fa-chevron-down {
    transform: rotate(180deg); } }


.accordion-menu li:last-child .dropdownlink {
  border-bottom: 0; }

.dropdownlink {
  cursor: pointer;
  display: block;
  padding: 15px 15px 15px 45px;
  font-size: 18px;
  border-bottom: 1px solid #ccc;
  color: #212121;
  position: relative;
  transition: all 0.4s ease-out;
  i {
    position: absolute;
    top: 13px;
    left: 16px;
    font-size: 14px; }

  .fa-chevron-down {
    right: 12px;
    left: auto; } }



.submenuItems {
  display: none;
  background: #1f1f1f;
  li {
    border-bottom: 1px solid #252525;

    &:last-child {
      border-bottom: none; } } }

.submenuItems a {
  display: block;
  color: #FFF;
  padding: 12px;
  transition: all 0.4s ease-out;
  text-decoration: none;

  &:hover, &:focus {
    background-color: $color_theme;
    color: #fff; } }


.menu-toggle {
  position: relative;
  width: 30px;
  height: 23px;
  margin: 6px;
  // display: none

  &:hover {
    cursor: pointer; }

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $color-theme;
    transition: all 0.5s ease-in-out;

    &:nth-child(2) {
      top: 50%;
      margin-top: -1px; }

    &:nth-child(3) {
      top: auto;
      bottom: 0; } }

  &.active {
    > div {
      &:nth-child(1) {
        transform: translateY(10px) rotate(135deg); }

      &:nth-child(2) {
        opacity: 0; }

      &:nth-child(3) {
        transform: translateY(-12px) rotate(-135deg); } } } }



/* Responsive */
@media screen and (max-width: 1024px) {
  .top-menu {
    .top-contact {

      li {
        padding: 0 5px;
        font-size: 11px; } } }

  .top-menu {
    .dt-social-link {
      li {
        margin: 0 3px; } }

    .login {
      a {
        padding: 8px 13px; } } }

  .navbar {
    padding: 10px 0;
    .nav {
      li {
        padding: 14px 7px; } } }

  .navbar-brand {
    width: 125px;

    img {
      width: 100%; } }

  #header {
    &.header-two {
      .navbar {
        .top-contact {
          right: -50px; } } } } }

.collapse-button {
    margin: 0 auto;
    width: 35px;
    display: block;
    transition: all .3s;
    cursor: pointer;
    height: 26px;
    padding: 0;
    background: transparent;
    // top: 20%
    right: 0;

    .icon-bar {
      transition: all .3s;
      height: 2px;
      width: 100%;
      display: block;
      background-color: white; }

    .icon-bar:nth-of-type(2) {
      margin: 8px 0; }

    &--closed {
      transition-delay: .3s;
      .icon-bar {
        float: right; }
      .icon-bar:nth-of-type(2) {
        width: 100%;
        transition-property: margin, height, width;
        transition-delay: .3s, .3s, 0s; }
      .icon-bar:nth-of-type(3) {
        width: 100%; }
      &:hover {
        .icon-bar:nth-of-type(2) {
          width: 100%; }
        .icon-bar:nth-of-type(3) {
          width: 100%; } } }

    &--opened {
      padding-top: 12px;
      .icon-bar:nth-of-type(1) {
        transform: rotate(45deg);
        transition-delay: .3s;
        height: 3px; }
      .icon-bar:nth-of-type(2) {
        opacity: 0;
        height: 0;
        margin: -3px; }
      .icon-bar:nth-of-type(3) {
        transform: rotate(-45deg);
        transition-delay: .3s;
        height: 3px; } } }

.collapse-button:hover, .collapse-button:focus {
  background-color: transparent; }

/* Respomsive */

@media screen and (max-width: 992px) {

  #header {
    &.header-three {
      .navbar {
        .slimmenu {
          li {
            a {
              padding: 13px 7px; }

            &.login {
              top: 5px; } } } } } } }

@media screen and (max-width: 768px) {

  .navbar-right {
    float: none; }

  .top-menu, .navbar-header {
    display: none; }

  #header {
    &.navbar-small {
      .navbar {
        position: relative; } } }

  .sub-toggle {
    display: block; }

  #header {
    &.navbar-small {
      position: relative;

      .navbar {
        background: #171717; } }

    &.header-two {
      .navbar {
        background: #333; }

      &.navbar-small {
        position: relative; }

      .top-contact {
        display: none; } } }


  .navbar {
    padding: 0;
    .slimmenu {
      li {
        padding: 0;
        border-bottom: 1px solid;

        a {
          padding: 10px; }

        .child-menu {
          width: 100%;
          li {
            background: #222; } } } } }

  ul {
    &.slimmenu {
      &.collapsed {
        li {
          background: #000;
          .sub-toggle {
            height: 50px;
            color: #FFF; } } } } }

  .btn-search {
    padding-bottom: 10px; }

  .menu-collapser {
    background-color: transparent;

    .mobile-logo {
      background: url(../img/logo.png);
      display: inline-block;
      height: 50px;
      width: 150px;
      background-repeat: no-repeat; } }

  #header {
    &.header-three {
      .navbar {
        // padding: 0 0 15px
        .slimmenu {
          li {
            &.login {
              position: relative;
              background-color: transparent;
              border-bottom: none;
              padding: 5px 0 15px; }


            a {
              color: #FFF; } } } }

      .collapse-button {
        .icon-bar {
          background: #000; } } } } }
