#hosting {
  padding: 100px 0; }

.hosting {
  margin-top: 80px;

  .feature-item {
    text-align: center;
    background: #f5f5f7;
    padding: 40px 20px;
    transition: all 0.3s ease-out;
    border: 1px solid transparent;
    cursor: pointer;
    margin-bottom: 30px;

    .host-icon {
      height: 60px;
      width: 60px;
      margin: 0 auto;
      transition: all 0.3s ease-out; }


    h3 {
      color: #0e0e0e;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 20px; }

    p {
      margin-bottom: 0; }


    &:hover {
      background: #FFF;
      border: 1px solid #dfe4e7;

      h3 {
        color: $color_theme;
        text-decoration: underline; } } } }

.domain-service {
  .feature-item {
    padding: 40px 14px;
    background: #f5f5f7;
    margin-bottom: 50px;
    .host-icon {
      position: absolute;
      left: 50%;
      top: -35px;
      transform: translateX(-50%);
      height: 70px;
      width: 70px; }

    p {
      color: #5f5f5f;
      font-weight: 300; } } }

.solution {

  .feature-item {
    padding: 40px 14px;
    text-align: left;
    margin-bottom: 50px;
    position: relative;

    .host-icon {
      position: absolute;
      left: 50px;
      top: -35px; } } }

@media screen and (max-width: 768px) {

  .hosting {
    .feature-item {
      max-width: 400px;
      margin: 0 auto;
      margin-bottom: 30px; } } }

