.about-center {
  h2 {
    color:#0e0e0e {}
    font-size: 36px;
    text-transform: uppercase; }
  h5 {
    color: #5f5f5f;
    font-size: 16px;
    font-weight: 300;
    text-decoration: underline;
    margin-bottom: 30px; }

  p {
    margin-bottom: 30px;
    line-height: 26px;
    color: #5f5f5f;
    font-weight: 300; } }

.ab-center-wrapper {
  border-bottom: 1px solid #dfe4e7;
  padding: 80px 0; }

.youtube-banner {
  height: 100%;
  overflow: hidden; }

.youtube-wrapper {
  position: relative;
  padding-bottom: 56.20%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background-color: #191a1c;
  cursor: pointer;


  i {

    font-size: 20px;
    margin-top: 7px;
    margin-left: 3px;
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    z-index: 2;
    cursor: pointer;
    visibility: visible;
    transform: translate3d(-50%, -50%, 0) scale(1);
    transition: transform .2s, opacity .2s; }


  &:after {
    content: '';
    position: absolute;
    margin-top: 6px;
    height: 55px;
    width: 55px;
    border: none;
    border-radius: 100px;
    background-color: $color_theme;
    cursor: pointer;
    top: 50%;
    left: 50%;
    visibility: visible;
    transform: translate3d(-50%, -50%, 0) scale(1);
    transition: background-color .2s, transform .2s, opacity 1.2s; }


  &.reveal:after, &.reveal i {
    opacity: 0;
    visibility: hidden;
    transition: visibility .4s, opacity .4s; }

  &.reveal {
    iframe {
      visibility: visible; } }

  iframe,object, embed {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    visibility: hidden; } }

.youtube-wrapper {
  &:before {
    content: '';
    position: absolute;
    left: -5px;
    top: -5px;
    border: 8px solid $color_theme;
    height: 67px;
    width: 67px;
    opacity: 0.5;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -41%, 0);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2); }

  .youtube-poster {
    height: 370px;
    background-position: center;
    background-size: cover; } }

@media screen and (max-width: 768px) {

  .ab-center-wrapper {
    padding: 50px 0; }

  .about-center {
    h2 {
      font-size: 30px; } } }

