#feature {
  padding: 100px 0; }

.feature {
  margin-top: 80px;
  .feature-item {
    position: relative;
    background-color: transparent;

    .feature-header {
      margin-bottom: 5px;

      .feature-icon {
        float: left;
        height: 50px;
        width: 50px;
        margin-top: -9px; }

      .feature-title {
        h3 {
          color: #0e0e0e;
          font-weight: 700;
          font-size: 18px;
          line-height: 18px;
          margin-left: 15px;
          display: inline-block; } } }

    .content {
      p {
        color: #5f5f5f;
        font-size: 14px;
        line-height: 26px;
        font-weight: 300; } } }


  > div:nth-child(1),
  > div:nth-child(2) {
    .feature-item {
      &:after {
        position: absolute;
        content: '';
        height: 70px;
        width: 70px;
        background: #FFF;
        border: 1px solid #eef1f2;
        right: -36px;
        transform: rotate(45deg);
        bottom: -35px;
        z-index: 100; } } } }

.border-br {
  border-right: 1px solid #dfe4e7;
  border-bottom: 1px solid #dfe4e7;
  padding: 0 20px 39px 20px; }

.border-right {
  border-right: 1px solid #dfe4e7;
  padding: 49px 25px 0; }

.border-none {
  border-right: none; }

@media screen and (max-width: 992px) {

  .border-br, .border-right {
    border: none; }

  .feature {
    > div:nth-child(1) ,div:nth-child(2) {
      .feature-item {
        &:after {
          display: none; } } } } }

@media screen and (max-width: 768px) {

  .border-br, .border-right {
    border: none; }

  .feature {
    > div:nth-child(1) ,div:nth-child(2) {
      .feature-item {
        &:after {
          display: none; } } } } }

