#contact {
  padding-top: 100px; }


.contact {
  p {
    color: #5f5f5f;
    font-weight: 300;
    margin-bottom: 40px; } }

.contact-title {
  font-size: 26px;
  color: #0e0e0e;
  margin-top: 0; }


#contact-form {
  .form-control {
    border: 1px solid #dfe4e7;
    outline: none;
    border-radius: 0;

    &:focus {
      outline: none;
      box-shadow: none; } }

  input {
    height: 60px; }

  textarea {
    height: 230px; }


  .dt-btn {
    border: none;
    padding: 14px 37px;
    margin-top: 30px; } }


.contact-address {
  margin-top: 120px;
  .address {
    background: #f5f5f7;
    padding: 20px 20px 20px 50px;
    position: relative;
    margin-left: 45px;
    margin-bottom: 70px;

    h5 {
      color: #0e0e0e;
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase; }


    .con-icon {
      height: 90px;
      width: 90px;
      border-radius: 50%;
      line-height: 106px;
      position: absolute;
      text-align: center;
      top: -40px;
      left: -40px;
      border: 1px solid transparent;

      &:after {
        content: '';
        position: absolute;
        height: 88px;
        width: 88px;
        background: #eeeeee;
        z-index: 1;
        left: 0px;
        top: 0px;
        border-radius: 50%;
        transition: all 0.3s ease-in-out; }

      i {
        font-size: 40px;
        color: $color_theme;
        z-index: 100;
        position: relative; } }

    &:hover {
      .con-icon {
        border-color: $color_theme;

        &:after {
          background: $color_theme;
          left: 4px;
          top: 4px;
          height: 80px;
          width: 80px; }

        i {
          color: #FFF; } } }

    p {
      margin-bottom: 0; } } }

.google-map {
  margin-top: 50px; }

.gmap3-area {
  height: 400px;
  width: 100%; }
