#server-hosting {
  padding: 100px 0; }

.section-title-three {
  border-right: none;
  h5 {
    text-decoration: underline; } }

.hosting-price {
  .table {
    border-collapse: separate;
    border-spacing: 0 8px;

    tr {
      th {
        background: $color_theme;
        font-weight: 400;
        color: #FFF;
        font-size: 18px;
        padding: 17px 0; }

      td {
        background: #f5f5f7;
        border-bottom: 2px solid #cccccc;
        margin-bottom: 10px;

        &:first-child {
          width: 300px; }
        h4 {
          float: left;
          font-size: 16px;
          color: #0e0e0e;
          margin: 13px 20px 0 0; }


        p {
          color: #0e0e0e;
          font-size: 14px;
          margin-bottom: 0; }

        span {
          color: #5f5f5f;
          font-size: 14px; }

        h3 {
          color: $color_theme;
          font-size: 18px;
          display: inline-block; }

        .dt-btn {
          padding: 10px 27px;
          float: right;
          margin: 15px 20px 0 0; } } } } }

.table {
  > tbody {
    > tr {
      margin-bottom: 10px;
      > td {
        vertical-align: middle;
        padding: 0 10px;
        border-top: 0; } } } }

// @media screen and (max-width: 992px)
//   .hosting-price
//     .table
//       tr
//         td
//           > h4
//             font-size: 14px
//             margin-right: 10px

//           p
//             font-size: 13px

//           span
//             font-size: 12px

//           &:first-child
//             width: 213px


table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px; }


th {
  background: $color_theme;
  color: #FFF;
  font-weight: 700;
  padding: 20px 15px;
  text-transform: uppercase; }

tbody {
  tr {
    td {
      background: #f5f5f7;
      padding: 0 10px;

      &:first-child {
        width: 300px; }
      h4 {
        float: left;
        font-size: 16px;
        color: #0e0e0e;
        margin: 13px 20px 0 0; }


      p {
        color: #0e0e0e;
        font-size: 14px;
        margin-bottom: 0; }

      span {
        color: #5f5f5f;
        font-size: 14px; }

      h3 {
        color: $color_theme;
        font-size: 18px;
        display: inline-block; }

      h5 {
        margin: 0; }

      p {
        line-height: 16px; }

      .dt-btn {
        padding: 10px 27px;
        float: right;
        margin: 10px 20px 0 0;

        &:hover {
          padding: 10px 27px; } } } } }



@media screen and (max-width: 768px) {
  // (min-device-width: 768px) and (max-device-width: 1024px)

  /* Force table to not be like tables anymore */
  table, thead, tbody, th, td, tr {
    display: block; }

  tr {
    &:nth-of-type(odd) {
      background: #f5f5f5; } }



  /* Hide table headers (but not display: none, for accessibility) */
  thead {

    tr {
      position: absolute;
      top: -9999px;
      left: -9999px; } }

  tbody {
    tr {
      td {
        padding: 10px;
        background-color: transparent;

        h4 {
          margin: 11px 20px 0 0; }

        h3 {
          margin: 0; }

        .dt-btn {
          margin: 0;
          padding: 8px 27px; }

        &:first-child {
          width: 100%; } } } }


  tr {
    border: 1px solid #ccc; }

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50% !important; }


  td:before {
    /* Now like a table header */
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px; }


  td:nth-of-type(1) {
    &:before {
      content: "CPU"; } }

  td:nth-of-type(2) {
    &:before {
      content: "Ram"; } }

  td:nth-of-type(3) {
    &:before {
      content: "Disk"; } }

  td:nth-of-type(4) {
    &:before {
      content: "Bandwidth"; } }

  td:nth-of-type(5) {
    &:before {
      content: "Price"; } } }

@media screen and (max-width: 500px) {
  td {
    padding-left: 38% !important;
    &:before {
      width: 30%; } }

  tbody {
    tr {
      td {
        h4 {
          margin: 11px 10px 0 0;
          font-size: 13px; }

        h3 {
          font-size: 16px; }

        p, span, h5 {
          font-size: 13px; } } } } }

