#help {
  padding: 100px 0;
  text-align: center;
  background-size: cover;

  h2 {
    color: #FFF;
    margin-top: 0; }

  p {
    color: #FFF;
    margin-bottom: 40px;
    line-height: 26px; }

  #form {
    input[type="text"] {
      max-width: 500px;
      width: 100%;
      height: 50px;
      background: rgba(0,0,0,0.2);
      border: 1px solid $color_theme;
      padding: 10px;
      outline: none;
      color: #FFF; }

    .dt-btn {
      border: none;
      padding: 12px 37px;

      &:hover {
        background: #000; } } } }

@media screen and (max-width: 768px) {
  #help {
    padding: 50px 0;
    p {
      br {
        display: none; } }

    #form {
      input[type="text"] {
        max-width: 330px; } } } }



@media screen and (max-width: 500px) {
  #help {


    #form {
      input[type="text"] {
        max-width: 200px; }

      .dt-btn {
        padding: 12px 20px; } } } }
