#feature-hosting {
  padding-top: 100px;
  background: #f5f5f7; }

.feature-hosting {
  h2 {
    color: #0e0e0e;
    font-weight: 700;
    font-size: 30px; }


  h3 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 300;
    text-decoration: underline;
    margin-bottom: 30px;
    margin-top: 0; }


  ul {
    margin: 0;
    padding: 0 0 30px 0;
    list-style: none;
    li {
      line-height: 30px;

      i {
        margin-right: 10px;
        color: $color_theme; } } } }

.fea-thumb {
  img {
    width: 100%; } }
