#answer {
  background: $color_theme;
  padding: 30px 0; }
.answer {
  .content {
    display: inline-block;
    h3 {
      color: #FFF;
      font-weight: 700;
      font-size: 22px;
      margin-top: 0; }

    p {
      color: #FFF;
      font-size: 18px;
      margin-bottom: 0; } }

  .dt-btn {
    float: right;
    background: #000;
    color: #FFF;
    display: inline-block;
    margin-top: 5px; } }
