#page-banner {
  padding: 100px 0;
  background-size: cover; }

.page-heading {
  padding: 20px 0;
  background: rgba($color_theme, 0.5);

  position: relative;
  max-width: 350px;

  &:before {
    position: absolute;
    content: '';
    height: 100px;
    width: 399px;
    left: -114%;
    top: 0;
    background: rgba($color_theme, 0.5); }

  h1 {
    margin: 0;
    color: #FFF;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 30px; } }

.page-link {
  a {
    margin-right: 20px;
    color: #FFF;
    font-size: 12px;
    position: relative;

    &:after {
      content: '/';
      position: absolute;
      right: -17px;
      top: -5px; }

    &:last-child {
      &:after {
        display: none; } }

    &:hover {
      color: #000; } } }

